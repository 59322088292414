import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import {WorkSheet} from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ReportExportSpreadsheetService {

  constructor() {
  }

  static export(records: any[]): void {
    import('xlsx').then(xlsx => {
      const workSheet: WorkSheet = xlsx.utils.json_to_sheet(records);
      const workBook = {
        Sheets: {
          'data': workSheet
        },
        SheetNames: [
          'data'
        ]
      };
      const excelBuffer: any = xlsx.write(workBook, {
        bookType: 'xlsx',
        type: 'array'
      });
      this.save(excelBuffer, 'report');
    });
  }

  private static save(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
