import {Injectable} from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class ReportExportDocumentService {

  constructor() {
  }

  static export(title: string, columns: any[], records: any[]): void {
    const DOCUMENT_EXTENSION = '.pdf';
    const doc = new jsPDF('landscape');

    // Reference: https://stackoverflow.com/questions/69059687/jspdf-autotable-color-in-header-and-center-text-in-rows
    const logo = new Image();
    logo.src = 'assets/images/logo.png';
    doc.addImage(logo, 'png', 14, 14, 60, 13);

    // TODO: Use SVG instead
    // Reference: https://codepen.io/eyaylagul/pen/XoNMYE
    // Reference: https://stackoverflow.com/questions/27706956/render-svg-to-pdf-using-jspdf
    // Reference: https://www.npmjs.com/package/canvg
    // Reference: https://stackoverflow.com/questions/59247659/integrating-canvg-3-0-with-angular-project
    // Get svg markup as string
    // var svg = document.getElementById('svg-container').innerHTML;
    //
    // if (svg)
    //   svg = svg.replace(/\r?\n|\r/g, '').trim();
    //
    // var canvas = document.createElement('canvas');
    // var context = canvas.getContext('2d');
    //
    //
    // context.clearRect(0, 0, canvas.width, canvas.height);
    // canvg(canvas, svg);
    //
    // var imgData = canvas.toDataURL('image/png');
    //
    // // Generate PDF
    // var doc = new jsPDF('p', 'pt', 'a4');
    // doc.addImage(imgData, 'PNG', 0, 0, 500, 500);

    title = 'Relatório: ' + title;
    doc.text(title, doc.internal.pageSize.width - 15, 24, {
      align: 'right'
    });
    doc.setFontSize(10);
    doc.text('As informações apresentadas neste relatório foram extraídas do Sistema de Gerenciamento de Merchandising e são restritas para uso interno do Banco do Brasil.', 14, 34);

    let headers: string[] = [];
    columns.forEach(col => headers.push(col.header));

    let rows: any[] = [];
    records.forEach(record => {
      const row: any[] = [];
      columns.forEach(col => row.push(record[col.field]));
      rows.push(row);
    });

    autoTable(doc, {
      startY: 38,
      theme: 'grid',
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.1,
        lineColor: [199, 199, 199]
      },
      alternateRowStyles: {
        fillColor: [250, 249, 248]
      },
      tableLineWidth: 0.1,
      tableLineColor: [199, 199, 199],
      head: [headers],
      body: rows
    });

    this.addFooter(doc, title);

    doc.save('report_export_' + new Date().getTime() + DOCUMENT_EXTENSION);
  }

  private static addFooter = (doc: jsPDF, title: string) => {
    // @ts-ignore
    const pageCount = doc.internal.getNumberOfPages();

    // doc.setFont('helvetica', 'italic');
    doc.setFontSize(8);

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text('Data: ' + (new Date).toLocaleString(), 15, 200);
      doc.text('Página ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width / 2, 200, {
        align: 'center'
      });
      doc.text('SGM - ' + title, doc.internal.pageSize.width - 15, 200, {
        align: 'right'
      });
    }
  }

}
