<div class="p-fluid grid formgrid">
  <div class="field col-12 md:col-3">
    <label for="r009_minuta">Minuta</label>
    <input id="r009_minuta" type="text" pInputText [(ngModel)]="filters.minuta"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r009_minutaStatus">Situação da minuta</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r009_minutaStatus" [options]="comboOptions.minutaStatus" [(ngModel)]="filters.minutaStatus"
                placeholder="Todos" optionLabel="label" optionValue="value" [filter]="true" filterBy="label"
                [showClear]="true" *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r009_package">Caixa</label>
    <input id="r009_package" type="number" pInputText [(ngModel)]="filters.package"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r009_packageStatus">Situação da caixa</label>
    <input id="r009_packageStatus" type="text" pInputText [(ngModel)]="filters.packageStatus"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r009_orders">Pedidos</label>
    <input id="r009_orders" type="text" pInputText [(ngModel)]="filters.orders"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r009_products">Peças</label>
    <input id="r009_products" type="text" pInputText [(ngModel)]="filters.products"/>
  </div>
</div>
