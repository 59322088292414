import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ChipModule} from 'primeng/chip';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {TableModule} from 'primeng/table';
import {ImageModule} from 'primeng/image';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {BlockUIModule} from 'primeng/blockui';
import {ProgressBarModule} from 'primeng/progressbar';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';
import {LoginComponent} from './pages/login/login.component';
import {TooltipModule} from 'primeng/tooltip';
import {InputTextModule} from 'primeng/inputtext';
import {SelectButtonModule} from 'primeng/selectbutton';
import {DialogModule} from 'primeng/dialog';
import {SkeletonModule} from 'primeng/skeleton';
import {RegisterCommunicationComponent} from './pages/register/register-communication/register-communication.component';
import {RegisterProductComponent} from './pages/register/register-product/register-product.component';
import {ReportComponent} from './pages/report/report.component';
import {
  ReportFilterReport001Component
} from './components/report/report-filter/report-filter-report001/report-filter-report001.component';
import {
  ReportFilterReport002Component
} from './components/report/report-filter/report-filter-report002/report-filter-report002.component';
import {
  ReportFilterReport003Component
} from './components/report/report-filter/report-filter-report003/report-filter-report003.component';
import {
  ReportFilterReport004Component
} from './components/report/report-filter/report-filter-report004/report-filter-report004.component';
import {
  ReportFilterReport005Component
} from './components/report/report-filter/report-filter-report005/report-filter-report005.component';
import {
  ReportFilterReport006Component
} from './components/report/report-filter/report-filter-report006/report-filter-report006.component';
import {
  ReportFilterReport007Component
} from './components/report/report-filter/report-filter-report007/report-filter-report007.component';
import {
  ReportFilterReport008Component
} from './components/report/report-filter/report-filter-report008/report-filter-report008.component';
import {
  ReportFilterReport009Component
} from './components/report/report-filter/report-filter-report009/report-filter-report009.component';
import {
  ReportFilterReport010Component
} from './components/report/report-filter/report-filter-report010/report-filter-report010.component';
import {
  ReportFilterReport011Component
} from './components/report/report-filter/report-filter-report011/report-filter-report011.component';
import {
  ReportFilterReport012Component
} from './components/report/report-filter/report-filter-report012/report-filter-report012.component';
import {
  ReportFilterReport013Component
} from './components/report/report-filter/report-filter-report013/report-filter-report013.component';
import {
  ReportFilterReport014Component
} from './components/report/report-filter/report-filter-report014/report-filter-report014.component';
import {SplitButtonModule} from 'primeng/splitbutton';
import {RegisterProductEditComponent} from './pages/register/register-product-edit/register-product-edit.component';
import {CharactersRemainingComponent} from './components/characters-remaining/characters-remaining.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ChipsModule} from 'primeng/chips';
import {
  RegisterCommunicationNewComponent
} from './pages/register/register-communication-new/register-communication-new.component';
import {
  RegisterCommunicationEditComponent
} from './pages/register/register-communication-edit/register-communication-edit.component';
import {FileUploadModule} from 'primeng/fileupload';
import {ImageCropperModule} from 'ngx-image-cropper';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CheckboxModule} from 'primeng/checkbox';
import {CdkDrag, CdkDragPlaceholder, CdkDropList} from '@angular/cdk/drag-drop';
import {ColorPickerModule} from 'primeng/colorpicker';
import {InputNumberModule} from 'primeng/inputnumber';
import {TabViewModule} from 'primeng/tabview';
import {FileSizePipe} from './pipes/file-size/file-size.pipe';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {CropperComponent} from './components/cropper/cropper.component';
import {TagModule} from 'primeng/tag';
import {MenuModule} from 'primeng/menu';
import {DividerModule} from 'primeng/divider';
import {RadioButtonModule} from 'primeng/radiobutton';
import { CurrencyMaskModule } from "ng2-currency-mask";

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    RegisterCommunicationComponent,
    RegisterProductComponent,
    ReportComponent,
    ReportFilterReport001Component,
    ReportFilterReport002Component,
    ReportFilterReport003Component,
    ReportFilterReport004Component,
    ReportFilterReport005Component,
    ReportFilterReport006Component,
    ReportFilterReport007Component,
    ReportFilterReport008Component,
    ReportFilterReport009Component,
    ReportFilterReport010Component,
    ReportFilterReport011Component,
    ReportFilterReport012Component,
    ReportFilterReport013Component,
    ReportFilterReport014Component,
    LoginComponent,
    RegisterProductEditComponent,
    CharactersRemainingComponent,
    RegisterCommunicationNewComponent,
    RegisterCommunicationEditComponent,
    FileSizePipe,
    CropperComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ChipModule,
    ButtonModule,
    RippleModule,
    TableModule,
    ImageModule,
    ConfirmDialogModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    BlockUIModule,
    ProgressBarModule,
    DropdownModule,
    FormsModule,
    CalendarModule,
    TooltipModule,
    InputTextModule,
    SelectButtonModule,
    DialogModule,
    SkeletonModule,
    SplitButtonModule,
    InputTextareaModule,
    ChipsModule,
    FileUploadModule,
    ImageCropperModule,
    AutoCompleteModule,
    CheckboxModule,
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
    ColorPickerModule,
    InputNumberModule,
    TabViewModule,
    ProgressSpinnerModule,
    TagModule,
    MenuModule,
    DividerModule,
    RadioButtonModule,
    CurrencyMaskModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pt-BR'},
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
