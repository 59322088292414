import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CommunicationService} from '../../../services/communication/communication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {environment} from '../../../../environments/environment';
import {SessionService} from '../../../services/session/session.service';
import {ScrollService} from '../../../services/scroll/scroll.service';
import {FileUpload} from 'primeng/fileupload';
import {HttpErrorResponse} from '@angular/common/http';
import {FileService} from '../../../services/file/file.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {ValidatorService} from '../../../services/validator/validator.service';
import {Communication} from '../../../models/communication';

@Component({
  selector: 'app-register-communication-edit',
  templateUrl: './register-communication-edit.component.html',
  styleUrls: ['./register-communication-edit.component.scss']
})
export class RegisterCommunicationEditComponent implements OnInit {

  id: any;
  isLoading: boolean = false;

  name: string = '';
  image: string = '';
  // fileUploadChooseLabel: string = 'Selecionar imagem';
  @ViewChild('imageInput')
    // imageInput: ElementRef<FileUpload> | null = null;
  imageInput: FileUpload | null = null;
  imageInputEl: ElementRef | null = null;

  canCropImage: boolean = false;
  fileName: string = '';
  imageBase64: string = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    private communicationService: CommunicationService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    if (environment.useToken) {
      this.route.queryParams.subscribe(params => {
        if (params['token']) {
          SessionService.setToken(params['token']);
          this.init();
        } else {
          this.router.navigateByUrl('/login').then(() => {
          });
        }
      });
    } else {
      this.init();
    }
  }

  private init(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.id = paramMap.get('id');
    });

    console.log(this.id);
    this.communicationService.get(this.id).then((item: any) => {
      console.log(item);
      this.name = item.name;
      this.image = item.image;
    }).catch((reason: HttpErrorResponse) => {
      console.log(reason);

      if (reason.status === 404) {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'O registro não foi encontrado.'
        });
      }

      this.cancel();
    });

    ScrollService.toTop();
  }

  public cancel(): void {
    this.router.navigate(['register', 'communication'], {
      queryParams: {
        token: SessionService.getToken()
      }
    });
  }

  public confirm(): void {
    if (ValidatorService.isEmpty(this.name)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O nome precisa ser fornecido.'
      });
      return;
    }

    // if (ValidatorService.isEmpty(this.fileName)) {
    //   this.messageService.add({
    //     severity: 'warn',
    //     summary: 'Aviso',
    //     detail: 'A imagem precisa ser fornecida.'
    //   });
    //   return;
    // }

    const communication = {
      name: this.name,
      image: this.croppedImage
    } as Communication;
    this.communicationService.update(this.id, communication).then(data => {
      // console.log(data);
      // this.items = data;
      // this.items.forEach(communication => this.ordered.push(communication.id!));
      // this.isLoading = false;
      //
      // if (callback) callback.call();

      if (data) {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'As informações foram salvas.'
        });
        this.cancel();
      }
    });
  }

  public onFileUpload(event: any): void {
    // console.log(event);

    this.imageInput?.clear();

    // // @ts-ignore
    // // ElementRef
    // this.imageInputEl = this.imageInput?.el.nativeElement;
    //
    // if (this.imageInputEl !== null) //
    //   this.renderer.setStyle(this.imageInputEl, 'display', 'none');

    // if (event.files.length > 0) {
    //   const file = event.files[0];
    //   // const type = file.type;
    //
    //   FileService.load(file).then((base64: string): any => {
    //     // console.log(base64);
    //     this.imageBase64 = base64;
    //     // this.fileBlob = this.b64Blob([base64], type);
    //     // console.log(this.fileBlob)
    //   });
    // }

    if (event.files.length > 0) //
      FileService.load(event.files[0]).then((base64: string): any => {
        // this.fileUploadChooseLabel = 'Mudar imagem';
        this.fileName = event.files[0].name;
        this.canCropImage = true;
        this.imageBase64 = base64;
      });
  }

  // public fileChangeEvent(event: any): void {
  //   this.imageChangedEvent = event;
  // }

  public imageCropped(event: ImageCroppedEvent): void {
    // console.log(event);
    this.croppedImage = event.base64;
  }

  public imageLoaded(): void {
    // show cropper
  }

  public cropperReady(): void {
    // cropper ready
  }

  public loadImageFailed(): void {
    // show message
  }

}
