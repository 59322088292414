import {Injectable} from '@angular/core';
import {LazyLoadEvent} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor() {
  }

  static getProperties(event: LazyLoadEvent) {
    const properties = {
      filters: [], // array
      first: event.first, // number
      rows: event.rows, // number
      sorts: [] // array
    };

    if (event.filters)
      Object.keys(event.filters).map((key: string) => {
        try {
          // @ts-ignore
          const o = event.filters[key];

          // @ts-ignore
          properties.filters.push({
            field: key, // string
            value: o.value, // string
            matchMode: o.matchMode // string
          });
        } catch (e) {
          // Do nothing.
        }
      });

    if (event.sortField) //
      // @ts-ignore
      properties.sorts.push({
        field: event.sortField, // string
        order: event.sortOrder // number
      });

    // return '?' +
    //   Object.keys(properties)
    //     // @ts-ignore
    //     .map(key => `${key}=${encodeURIComponent(JSON.stringify(properties[key]))}`)
    //     .join('&');
    return properties;
  }

}
