import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {

  constructor() {
  }

  static management(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';

    if ((error.error instanceof ErrorEvent) || (typeof error.error['message'] === 'string')) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    // console.log(errorMessage);

    return throwError(() => errorMessage);
  }

}
