<div class="p-fluid grid formgrid">
  <div class="field col-12 md:col-3">
    <label for="r001_code">Código</label>
    <input id="r001_code" type="text" pInputText [(ngModel)]="filters.code"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r001_kind">Tipo da peça</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r001_kind" [options]="comboOptions.kind" [(ngModel)]="filters.kind" placeholder="Todos"
                optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r001_availability">Disponibilidade</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r001_availability" [options]="comboOptions.availability" [(ngModel)]="filters.availability"
                placeholder="Todos" optionLabel="label" optionValue="value" [filter]="true" filterBy="label"
                [showClear]="true" *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r001_validity">Validade</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r001_validity" [options]="comboOptions.validity" [(ngModel)]="filters.validity" placeholder="Todos"
                optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r001_inventory">Estoque</label>
    <p-selectButton id="r001_inventory" [options]="inventoryOptions" [(ngModel)]="filters.inventory" optionLabel="label"
                    optionValue="value"></p-selectButton>
  </div>
</div>
