import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportFilterReport010Service {

  constructor() {
  }

  static generate(filters: any, afilters: any[]) {
    if (filters.enrollment !== undefined) //
      afilters.push({
        field: 'matricula',
        value: filters.enrollment,
        matchMode: 'startsWith'
      });

    if (filters.name !== undefined) //
      afilters.push({
        field: 'nome',
        value: filters.name,
        matchMode: 'startsWith'
      });

    if (filters.prefix !== undefined) //
      afilters.push({
        field: 'prefixo',
        value: filters.prefix,
        matchMode: 'startsWith'
      });

    if (filters.dependency !== undefined) //
      afilters.push({
        field: 'dependencia',
        value: filters.dependency,
        matchMode: 'startsWith'
      });

    if (filters.state !== undefined) //
      afilters.push({
        field: 'uf',
        value: filters.state,
        matchMode: 'startsWith'
      });

    if (filters.role !== undefined) //
      afilters.push({
        field: 'perfil',
        value: filters.role,
        matchMode: 'startsWith'
      });

    if (filters.accessLevel !== undefined) //
      afilters.push({
        field: 'nivelAcesso',
        value: filters.accessLevel,
        matchMode: 'startsWith'
      });

    if (filters.status !== undefined) //
      afilters.push({
        field: 'situacao',
        value: filters.status,
        matchMode: 'startsWith'
      });
  }

}
