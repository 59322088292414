<div class="p-fluid grid formgrid">
  <div class="field col-12 md:col-3">
    <label for="r012_id">Identificação</label>
    <input id="r012_id" type="number" pInputText [(ngModel)]="filters.id"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r012_code">Código</label>
    <input id="r012_code" type="text" pInputText [(ngModel)]="filters.code"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r012_kind">Tipo da peça</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r012_kind" [options]="comboOptions.kind" [(ngModel)]="filters.kind" placeholder="Todos"
                optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r012_status">Situação</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r012_status" [options]="comboOptions.status" [(ngModel)]="filters.status" placeholder="Todos"
                optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r012_advertising">Ação de comunicação</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r012_advertising" [options]="comboOptions.advertising" [(ngModel)]="filters.advertising"
                placeholder="Todos" optionLabel="label" optionValue="value" [filter]="true" filterBy="label"
                [showClear]="true" *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r012_demandant">Área demandante</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r012_demandant" [options]="comboOptions.demandant" [(ngModel)]="filters.demandant"
                placeholder="Todos" optionLabel="label" optionValue="value" [filter]="true" filterBy="label"
                [showClear]="true" *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r012_inventory">Estoque</label>
    <p-selectButton id="r012_inventory" [options]="inventoryOptions" [(ngModel)]="filters.inventory"
                    optionLabel="label" optionValue="value"></p-selectButton>
  </div>
</div>
