import {Component, OnInit} from '@angular/core';
import {ComboBoxOption} from '../../models/combo-box-option';
import {ReportService} from '../../services/report/report.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {
  ReportExportSpreadsheetService
} from '../../services/report/report-export/report-export-spreadsheet/report-export-spreadsheet.service';
import {
  ReportExportDocumentService
} from '../../services/report/report-export/report-export-document/report-export-document.service';
import {ReportFilterService} from '../../services/report/report-filter/report-filter.service';
import {ReportComboOptionsService} from '../../services/report/report-combo-options/report-combo-options.service';
import {MessageService} from 'primeng/api';
import {environment} from '../../../environments/environment';
import {ScrollService} from '../../services/scroll/scroll.service';
import {SessionService} from '../../services/session/session.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  reports!: ComboBoxOption[];
  selectedReport!: ComboBoxOption;
  reportKinds!: ComboBoxOption[];
  selectedReportKind!: ComboBoxOption;
  start!: Date;
  end!: Date;

  filters: any = {};
  filtersAsString: string = '';

  isLoading: boolean = false;
  isLoadingComboOptions: boolean = false;
  isFilterTouched: boolean = false;

  columns!: any[];
  records: any[] = [];

  comboOptions: any;
  reportTitle: string = '';
  displayExportDocumentReportTitle: boolean = false;

  selectedRecords: any[] = [];

  constructor(
    private reportService: ReportService,
    private reportComboOptionsService: ReportComboOptionsService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
  ) {
  }

  public ngOnInit(): void {
    if (environment.useToken) {
      this.route.queryParams.subscribe((params: Params): void => {
        if (params['token']) {
          SessionService.setToken(params['token']);
          this.init();
        } else {
          this.router.navigateByUrl('/login').then((): void => {
          });
        }
      });
    } else {
      this.init();
    }
  }

  private init(): void {
    ScrollService.toTop();

    this.reportComboOptionsService.clear(this.comboOptions, (newOptions: any) => this.comboOptions = newOptions);

    this.reportService.all().then(data => {
      this.reports = data;
      this.selectedReport = this.reports[0];

      this.reportKinds = JSON.parse(this.selectedReport.value as string);
      this.selectedReportKind = this.reportKinds[0];
    });

    this.start = new Date();
    this.end = new Date();

    let interval: any = setInterval((): void => {
      if ((this.selectedReport) && (this.selectedReportKind)) {
        this.clearFilter();
        this.loadComboOptions().then((): void => {
        });
        clearInterval(interval);
      }
    }, 500);
  }

  private load(): void {
    if (this.start === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O início do período precisa ser fornecido'
      });
      return;
    }

    if (this.end === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O final do período precisa ser fornecido'
      });
      return;
    }

    if (this.start.getTime() > this.end.getTime()) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O início do período precisa ser menor ou igual o final'
      });
      return;
    }

    this.columns = [];
    this.records = [];

    if ((this.selectedReport) && (this.selectedReportKind)) {
      this.isLoading = true;

      this.reportService.get(this.selectedReportKind!.value!, this.start, this.end, this.filtersAsString).then(data => {
        this.columns = data.cols;
        this.records = data.records;

        this.selectedRecords = [];
        this.records.forEach(record => this.selectedRecords.push(record));

        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    }
  }

  public onChangeReport(event: Event): void {
    console.log(event);
    this.reportKinds = JSON.parse(this.selectedReport.value as string);
    this.selectedReportKind = this.reportKinds[0];

    // Change values for today
    this.start = new Date();
    this.end = new Date();

    this.clearFilter();
    this.loadComboOptions().then((): void => {
    });
  }

  public onChangeReportKind(event: any): void {
    if (event == null) {
      this.reportKinds = JSON.parse(this.selectedReport.value as string);
      this.selectedReportKind = this.reportKinds[0];
    }

    // Change values for today
    this.start = new Date();
    this.end = new Date();

    this.clearFilter();
    this.loadComboOptions().then((): void => {
    });
  }

  public isReportKind(kind: string): boolean {
    return (this.selectedReportKind) && (this.selectedReportKind.value === kind);
  }

  public filter(): void {
    this.filtersAsString = ReportFilterService.generate(this.selectedReportKind.value, this.filters);
    this.isFilterTouched = true;
    this.load();
  }

  public clearFilter(): void {
    this.filters = {};
    this.filtersAsString = '';
    this.isFilterTouched = false;
    this.selectedRecords = [];
  }

  public exportSpreadsheet(): void {
    if (this.isNotSelectedRecords()) //
      return;

    ReportExportSpreadsheetService.export(this.selectedRecords);
  }

  public exportDocument(): void {
    if (this.isNotSelectedRecords()) //
      return;

    this.reportTitle = this.selectedReport.label + ' - Tipo: ' + this.selectedReportKind.label;
    this.displayExportDocumentReportTitle = true;
  }

  public exportDocumentWithTitle(): void {
    this.displayExportDocumentReportTitle = false;
    ReportExportDocumentService.export(this.reportTitle, this.columns, this.selectedRecords);
  }

  private async loadComboOptions(): Promise<void> {
    await this.reportComboOptionsService.clear(this.comboOptions, (newOptions: any) => this.comboOptions = newOptions);
    await this.reportComboOptionsService.load(this.comboOptions, this.selectedReportKind.value,
      (loading: boolean) => this.isLoadingComboOptions = loading,
      (newOptions: any) => this.comboOptions = newOptions);
  }

  private isNotSelectedRecords(): boolean {
    // console.log(this.selectedRecords);

    if (this.selectedRecords.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Precisa ser selecionado ao menos um resultado'
      });
      return true;
    }

    return false;
  }

}
