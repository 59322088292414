import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportFilterReport009Service {

  constructor() {
  }

  static generate(filters: any, afilters: any[]) {
    if (filters.minuta !== undefined) //
      afilters.push({
        field: 'minuta',
        value: filters.minuta,
        matchMode: 'startsWith'
      });

    if (filters.minutaStatus !== undefined) //
      afilters.push({
        field: 'minutaSituacao',
        value: filters.minutaStatus,
        matchMode: 'startsWith'
      });

    if (filters.package !== undefined) //
      afilters.push({
        field: 'caixa',
        value: filters.package,
        matchMode: 'startsWith'
      });

    if (filters.packageStatus !== undefined) //
      afilters.push({
        field: 'caixaSituacao',
        value: filters.packageStatus,
        matchMode: 'startsWith'
      });

    if (filters.orders !== undefined) //
      afilters.push({
        field: 'pedidos',
        value: filters.orders,
        matchMode: 'contains'
      });

    if (filters.products !== undefined) //
      afilters.push({
        field: 'pecas',
        value: filters.products,
        matchMode: 'contains'
      });
  }

}
