import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() {
  }

  public static isEmpty(o: any): boolean {
    if ((o === undefined) || (o === null)) //
      return true;

    if ((typeof o === 'string') && ((o.replace(/\s/g, '') === '') || (o.replace(/\s/g, '').toLowerCase() === 'null'))) //
      return true;

    if ((Array.isArray(o)) && (o.length === 0)) //
      return true;

    return false;
  }

}
