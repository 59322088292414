import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {SessionService} from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl + '/file';
    //this.baseUrl = 'https://file.io/';
  }

  public static load(file: File, type: string = 'base64'): Promise<any> {
    return new Promise((resolve, reject): void => {
      const reader: FileReader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error: ProgressEvent<FileReader>): void => reject(error);

      if (type === 'base64') //
        reader.readAsDataURL(file);

      if (type === 'array') //
        reader.readAsArrayBuffer(file);
    });
  }

  public static formatBytes(bytes: number, decimals: number = 2): string {
    if (!+bytes) //
      return '0 Bytes';

    const k: number = 1024;
    const dm: number = decimals < 0 ? 0 : decimals;
    const sizes: string[] = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const i: number = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  public upload(formData: FormData): Observable<HttpEvent<any>> {
    const token: string = SessionService.getToken();

    return this.http.post<any>(this.baseUrl + '/upload?token=' + token, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

}
