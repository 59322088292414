import {Injectable} from '@angular/core';
import {ReportFilterFieldService} from '../../report-filter-field/report-filter-field.service';

@Injectable({
  providedIn: 'root'
})
export class ReportFilterReport002Service {

  constructor() {
  }

  static generate(filters: any, afilters: any[]) {
    ReportFilterFieldService.hasCode(filters, afilters);
    ReportFilterFieldService.hasKind(filters, afilters);
    ReportFilterFieldService.hasAdvertising(filters, afilters);
  }

}
