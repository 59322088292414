<div class="p-fluid grid formgrid">
  <div class="field col-12 md:col-3">
    <label for="r008_order">Pedido</label>
    <input id="r008_order" type="number" pInputText [(ngModel)]="filters.order"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r008_code">Código</label>
    <input id="r008_code" type="text" pInputText [(ngModel)]="filters.code"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r008_owner">Responsável</label>
    <input id="r008_owner" type="text" pInputText [(ngModel)]="filters.owner"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r008_enrollment">Matrícula</label>
    <input id="r008_enrollment" type="text" pInputText [(ngModel)]="filters.enrollment"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r008_state">UF</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r008_state" [options]="comboOptions.state" [(ngModel)]="filters.state" placeholder="Todos"
                optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r008_approved">Aprovado</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r008_approved" [options]="comboOptions.approved" [(ngModel)]="filters.approved"
                placeholder="Todos" optionLabel="label" optionValue="value" [filter]="true" filterBy="label"
                [showClear]="true" *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r008_problem">Problema</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r008_problem" [options]="comboOptions.problem" [(ngModel)]="filters.problem"
                placeholder="Todos" optionLabel="label" optionValue="value" [filter]="true" filterBy="label"
                [showClear]="true" *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r008_problemKind">Tipo do problema</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r008_problemKind" [options]="comboOptions.problemKind" [(ngModel)]="filters.problemKind"
                placeholder="Todos" optionLabel="label" optionValue="value" [filter]="true" filterBy="label"
                [showClear]="true" *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r008_problemOther">Outro</label>
    <input id="r008_problemOther" type="text" pInputText [(ngModel)]="filters.problemOther"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r008_problemDetail">Detalhe</label>
    <input id="r008_problemDetail" type="text" pInputText [(ngModel)]="filters.problemDetail"/>
  </div>
</div>
