<div class="p-fluid grid formgrid">
  <div class="field col-12 md:col-3">
    <label for="r004_code">Código</label>
    <input id="r004_code" type="text" pInputText [(ngModel)]="filters.code"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r004_kind">Tipo da peça</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r004_kind" [options]="comboOptions.kind" [(ngModel)]="filters.kind" placeholder="Todos"
                optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r004_detail">Ficha técnica</label>
    <input id="r004_detail" type="text" pInputText [(ngModel)]="filters.detail"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r004_advertisingAgency">Agência de publicidade</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r004_advertisingAgency" [options]="comboOptions.advertisingAgency"
                [(ngModel)]="filters.advertisingAgency" placeholder="Todos" optionLabel="label"
                optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r004_provider">Fornecedor</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r004_provider" [options]="comboOptions.provider" [(ngModel)]="filters.provider"
                placeholder="Todos" optionLabel="label" optionValue="value" [filter]="true" filterBy="label"
                [showClear]="true" *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
</div>
