<div class="p-fluid grid formgrid">
  <div class="field col-12 md:col-3">
    <label for="r006_order">Pedido</label>
    <input id="r006_order" type="number" pInputText [(ngModel)]="filters.order"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r006_distributionCenter">Centro de distribuição</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r006_distributionCenter" [options]="comboOptions.distributionCenter"
                [(ngModel)]="filters.distributionCenter" placeholder="Todos" optionLabel="label"
                optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r006_place">Local da entrega</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r006_place" [options]="comboOptions.place" [(ngModel)]="filters.place" placeholder="Todos"
                optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r006_state">UF</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r006_state" [options]="comboOptions.state" [(ngModel)]="filters.state"
                placeholder="Todos" optionLabel="label" optionValue="value" [filter]="true" filterBy="label"
                [showClear]="true" *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r006_status">Situação</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r006_status" [options]="comboOptions.status" [(ngModel)]="filters.status" placeholder="Todos"
                optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
</div>
