<div class="p-fluid grid formgrid">
  <div class="field col-12 md:col-3">
    <label for="r010_enrollment">Matrícula</label>
    <input id="r010_enrollment" type="text" pInputText [(ngModel)]="filters.enrollment"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r010_name">Nome</label>
    <input id="r010_name" type="text" pInputText [(ngModel)]="filters.name"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r010_prefix">Prefixo</label>
    <input id="r010_prefix" type="number" pInputText [(ngModel)]="filters.prefix"/>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r010_dependency">Dependência</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r010_dependency" [options]="comboOptions.dependency" [(ngModel)]="filters.dependency"
                placeholder="Todos" optionLabel="label" optionValue="value" [filter]="true" filterBy="label"
                [showClear]="true" *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r010_state">UF</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r010_state" [options]="comboOptions.state" [(ngModel)]="filters.state" placeholder="Todos"
                optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r010_role">Perfil</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r010_role" [options]="comboOptions.role" [(ngModel)]="filters.role" placeholder="Todos"
                optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r010_accessLevel">Nível de acesso</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r010_accessLevel" [options]="comboOptions.accessLevel" [(ngModel)]="filters.accessLevel"
                placeholder="Todos" optionLabel="label" optionValue="value" [filter]="true" filterBy="label"
                [showClear]="true" *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
  <div class="field col-12 md:col-3">
    <label for="r010_status">Situação</label>
    <p-skeleton height="2.5rem" styleClass="mb-2" *ngIf="isLoadingComboOptions"></p-skeleton>
    <p-dropdown id="r010_status" [options]="comboOptions.status" [(ngModel)]="filters.status" placeholder="Todos"
                optionLabel="label" optionValue="value" [filter]="true" filterBy="label" [showClear]="true"
                *ngIf="!isLoadingComboOptions"></p-dropdown>
  </div>
</div>
