import {Component, OnInit} from '@angular/core';
import {Communication} from '../../../models/communication';
import {CommunicationService} from '../../../services/communication/communication.service';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';

import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {ScrollService} from '../../../services/scroll/scroll.service';
import {SessionService} from '../../../services/session/session.service';

@Component({
  selector: 'app-register-communication',
  templateUrl: './register-communication.component.html',
  styleUrls: ['./register-communication.component.scss']
})
export class RegisterCommunicationComponent implements OnInit {

  cols: any[] = [];
  items: Communication[] = [];
  ordered: number[] = [];

  isLoading: boolean = false;
  isReloading: boolean = false;
  isRemoving: boolean = false;
  isSaving: boolean = false;

  item: any;
  options: MenuItem[] = [];

  constructor(
    private communicationService: CommunicationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  public ngOnInit() {
    if (environment.useToken) {
      this.route.queryParams.subscribe(params => {
        if (params['token']) {
          SessionService.setToken(params['token']);
          this.init();
        } else {
          this.router.navigateByUrl('/login').then(() => {
          });
        }
      });
    } else {
      this.init();
    }
  }

  private init(): void {
    ScrollService.toTop();

    this.cols = [
      {field: 'id', header: '#', centered: true},
      {field: 'image', header: 'Capa', centered: true},
      {field: 'name', header: 'Nome', centered: false},
      {field: 'createdAt', header: 'Criado em', centered: true}
    ];

    this.load();

    // Options
    this.options = [
      // {
      //   label: 'Reimprimir',
      //   icon: 'fa-solid fa-print',
      //   command: (event: any) => {
      //     // console.log(event, this.item);
      //     // this.delete(event, this.item);
      //   }
      // },
      // {
      //   label: 'Duplicar',
      //   icon: 'fa-solid fa-copy',
      //   command: (event: any) => {
      //     // console.log(event, this.item);
      //     // this.delete(event, this.item);
      //   }
      // },
      // {
      //   label: 'Ativar',
      //   icon: 'fa-solid fa-eye',
      //   command: (event: any) => {
      //     // console.log(event, this.item);
      //     // this.delete(event, this.item);
      //   }
      // },
      // {
      //   label: 'Inativar',
      //   icon: 'fa-solid fa-eye-slash',
      //   command: (event: any) => {
      //     // console.log(event, this.item);
      //     // this.delete(event, this.item);
      //   }
      // },
      {
        label: 'Remover',
        icon: 'fa-solid fa-trash',
        command: (event: any) => {
          // console.log(event, this.item);
          this.delete(event, this.item);
        }
      }
    ];
  }

  private load(callback?: any): void {
    this.isLoading = true;

    this.items = [];
    this.ordered = [];

    this.communicationService.all().then(data => {
      this.items = data;
      this.items.forEach(communication => this.ordered.push(communication.id!));
      this.isLoading = false;

      if (callback) callback.call();
    });
  }

  public reload(): void {
    this.confirmationService.confirm({
      message: 'Deseja carregar novamente? <strong>Toda a edição será perdida.</strong>',
      header: 'Confirmação',
      icon: 'fa-solid fa-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      defaultFocus: 'reject',
      accept: () => {
        this.isReloading = true;

        this.messageService.add({
          severity: 'info',
          summary: 'Confirmado',
          detail: 'Você aceitou a ação de carregar novamente.'
        });
        this.load(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Os destaques foram carregados novamente.'
          });

          this.isReloading = false;
        });
      },
      // reject: (type: any) => {
      //   switch (type) {
      //     case ConfirmEventType.REJECT:
      //       this.messageService.add({
      //         severity: 'error',
      //         summary: 'Rejeitado',
      //         detail: 'Você rejeitou a ação de carregar novamente.'
      //       });
      //       break;
      //     case ConfirmEventType.CANCEL:
      //       this.messageService.add({
      //         severity: 'warn',
      //         summary: 'Cancelado',
      //         detail: 'Você cancelou a ação de carregar novamente.'
      //       });
      //       break;
      //   }
      // }
    });
  }

  public remove(): void {
    this.confirmationService.confirm({
      message: 'Deseja remover a ordenação? <strong>Toda a edição será perdida.</strong><br><br><em>A ordenação irá retornar para o formato original: iniciando do último cadastrado até o primeiro cadastrado.</em>',
      header: 'Confirmação',
      icon: 'fa-solid fa-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      defaultFocus: 'reject',
      accept: () => {
        this.isRemoving = true;

        this.messageService.add({
          severity: 'info',
          summary: 'Confirmado',
          detail: 'Você aceitou a ação de remover a ordenação.'
        });

        this.items.sort((a, b) => b.id! - a.id!);
        this.ordered = [];

        this.items.forEach(communication => this.ordered.push(communication.id!));

        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'A ordenação foi removida.'
        });

        this.isRemoving = false;
      },
      // reject: (type: any) => {
      //   switch (type) {
      //     case ConfirmEventType.REJECT:
      //       this.messageService.add({
      //         severity: 'error',
      //         summary: 'Rejeitado',
      //         detail: 'Você rejeitou a ação de remover a ordenação.'
      //       });
      //       break;
      //     case ConfirmEventType.CANCEL:
      //       this.messageService.add({
      //         severity: 'warn',
      //         summary: 'Cancelado',
      //         detail: 'Você cancelou a ação de remover a ordenação.'
      //       });
      //       break;
      //   }
      // }
    });
  }

  public save(): void {
    this.confirmationService.confirm({
      message: 'Deseja salvar a ordenação? <strong>Operação irreversível.</strong>',
      header: 'Confirmação',
      icon: 'fa-solid fa-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      defaultFocus: 'reject',
      accept: () => {
        this.isSaving = true;

        this.messageService.add({
          severity: 'info',
          summary: 'Confirmado',
          detail: 'Você aceitou a ação de salvar a ordenação.'
        });

        const orderedToSave = [];

        for (let i = 0; i < ((this.ordered.length < 9) ? this.ordered.length : 9); i++) {
          orderedToSave.push(this.ordered[i]);
        }

        // console.log(orderedToSave);

        this.communicationService.saverOrder(orderedToSave).then(data => {
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'A ordenação foi salva.'
          });

          this.isSaving = false;
        });
      },
      // reject: (type: any) => {
      //   switch (type) {
      //     case ConfirmEventType.REJECT:
      //       this.messageService.add({
      //         severity: 'error',
      //         summary: 'Rejeitado',
      //         detail: 'Você rejeitou a ação de salvar a ordenação.'
      //       });
      //       break;
      //     case ConfirmEventType.CANCEL:
      //       this.messageService.add({
      //         severity: 'warn',
      //         summary: 'Cancelado',
      //         detail: 'Você cancelou a ação de salvar a ordenação.'
      //       });
      //       break;
      //   }
      // }
    });
  }

  public onRowReorder(event: any): void {
    const index = this.ordered[event.dragIndex];
    this.ordered.splice(event.dragIndex, 1);
    this.ordered.splice(event.dropIndex, 0, index);
  }

  public add(): void {
    this.router.navigate(['register', 'communication', 'new'], {
      queryParams: {
        token: SessionService.getToken()
      }
    });
  }

  public edit(event: any, item: any): void {
    this.item = item;

    this.router.navigate(['register', 'communication', this.item.id], {
      queryParams: {
        token: SessionService.getToken()
      }
    });
  }

  public onOpenMenu(event: any, item: any): void {
    this.item = item;
  }

  public delete(event: Event, item: any): void {
    this.confirmationService.confirm({
      target: event.target!,
      header: 'Confirmação',
      message: 'Deseja realmente remover <strong>' + item.name + '</strong>?',
      icon: 'fa-solid fa-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      defaultFocus: 'reject',
      accept: () => {
        // this.communicationService.delete(item.id).subscribe({
        //   next: () => { // data
        //     this.load();
        //   },
        //   error: () => { // err
        //   },
        //   complete: () => {
        //   }
        // });
        this.communicationService.delete(item.id).then(() => this.load());
      },
      reject: () => {
        // reject action
      }
    });
  }

}
