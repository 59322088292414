import {Injectable} from '@angular/core';
import {ReportService} from '../report.service';

@Injectable({
  providedIn: 'root'
})
export class ReportComboOptionsService {

  constructor(private reportService: ReportService) {
  }

  clear(options: any, update: Function): void {
    options = {
      accessLevel: [],
      advertising: [],
      advertisingAgency: [],
      approved: [],
      availability: [],
      category: [],
      demandant: [],
      dependency: [],
      distributionCenter: [],
      kind: [],
      minutaStatus: [],
      packageStatus: [],
      place: [],
      problem: [],
      problemKind: [],
      provider: [],
      role: [],
      state: [],
      status: [],
      validity: [],
    };
    update(options);
  }

  async load(options: any, reportKind: number | string | boolean, updateIsLoading: Function, update: Function) {
    await this.clear(options, (newOptions: any[]) => options = newOptions);

    let fields: any[] = [];
    await this.fetchFields(reportKind, (newFields: any[]) => fields = newFields);

    if (fields.length == 0) //
      return;

    const promises: any[] = [];
    fields.forEach(field => promises.push(this.reportService.combobox(reportKind, field)));

    if (promises.length == 0) //
      return;

    await updateIsLoading(true);

    Promise
      .all(promises)
      .then((result: Awaited<any>[]) => this.processResult(result, fields, options, update))
      .finally(() => updateIsLoading(false));
  }

  private async fetchFields(reportKind: number | string | boolean, update: Function) {
    switch (reportKind) {
      case '001': // Peças > Geral
        update(['kind', 'availability', 'validity']);
        break;

      case '002': // Peças > Expurgo
        update(['kind', 'advertising']);
        break;

      case '003': // Peças > Avise-me
        update(['kind', 'advertising']);
        break;

      case '004': // Peças > Preços
        update(['kind', 'advertisingAgency', 'provider']);
        break;

      case '005': // Pedidos > Geral
        update(['distributionCenter', 'place', 'state', 'status']);
        break;

      case '006': // Pedidos > Cancelados
        update(['distributionCenter', 'place', 'state', 'status']);
        break;

      case '007': // Pedidos > Pesquisa de avaliação
        update(['state', 'approved', 'problem', 'problemKind']);
        break;

      case '008': // Pedidos > Pesquisa de avaliação (reprovadas)
        update(['state', 'approved', 'problem', 'problemKind']);
        break;

      case '009': // Pedidos > Caixa
        update(['minutaStatus', 'packageStatus']);
        break;

      case '010': // Usuários > Geral
        update(['dependency', 'state', 'role', 'accessLevel', 'status']);
        break;

      case '011': // Fale conosco > Geral
        update(['category', 'status', 'state', 'role']);
        break;

      case '012': // Peças > Estoque
        update(['kind', 'status', 'advertising', 'demandant']);
        break;
    }
  }

  private async processResult(result: Awaited<any>[], fields: any[], options: any[], update: Function) {
    let i = 0;
    fields.forEach(field => {
      options[field] = result[i];
      i++;
    });

    await update(options);
  }

}
