import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportFilterReport013Service {

  constructor() {
  }

  static generate(filters: any, afilters: any[]) {
  }

}
