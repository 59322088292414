import {Component, DoCheck, OnInit, ViewChild} from '@angular/core';

import {PrimeNGConfig} from 'primeng/api';
import {Toast} from 'primeng/toast';
import {DomHandler} from 'primeng/dom';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, DoCheck {

  @ViewChild(Toast)
  toast?: Toast;

  title = 'admin-ui';

  constructor(private config: PrimeNGConfig) {
  }

  ngOnInit() {
    this.config.ripple = true;
    this.config.setTranslation({
      'startsWith': 'Starts with',
      'contains': 'Contains',
      'notContains': 'Not contains',
      'endsWith': 'Ends with',
      'equals': 'Equals',
      'notEquals': 'Not equals',
      'noFilter': 'No Filter',
      'lt': 'Less than',
      'lte': 'Less than or equal to',
      'gt': 'Greater than',
      'gte': 'Greater than or equal to',
      'is': 'Is',
      'isNot': 'Is not',
      'before': 'Before',
      'after': 'After',
      'dateIs': 'Date is',
      'dateIsNot': 'Date is not',
      'dateBefore': 'Date is before',
      'dateAfter': 'Date is after',
      'clear': 'Clear',
      'apply': 'Apply',
      'matchAll': 'Match All',
      'matchAny': 'Match Any',
      'addRule': 'Add Rule',
      'removeRule': 'Remove Rule',
      'accept': 'Yes',
      'reject': 'No',
      'choose': 'Choose',
      'upload': 'Upload',
      'cancel': 'Cancel',
      'dayNames': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      'dayNamesShort': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      'dayNamesMin': ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      'monthNames': ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      'monthNamesShort': ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      'dateFormat': 'dd/mm/yy',
      'firstDayOfWeek': 0,
      'today': 'Today',
      'weekHeader': 'Wk',
      'weak': 'Weak',
      'medium': 'Medium',
      'strong': 'Strong',
      'passwordPrompt': 'Enter a password',
      'emptyMessage': 'No results found',
      'emptyFilterMessage': 'No results found'
    });
  }

  // DONE: 2022-01-13 Francisco: Fix problem with toast behind elements
  ngDoCheck(): void {
    if (!(this.toast && this.toast.containerViewChild)) return;
    const requiredZIndex = `${DomHandler.zindex}`;
    const el = this.toast.containerViewChild.nativeElement as HTMLElement;
    if (el.style.zIndex !== requiredZIndex) el.style.zIndex = requiredZIndex;
  }

}
