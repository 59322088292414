import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {DateService} from '../date/date.service';
import {lastValueFrom} from 'rxjs';
import {SessionService} from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl + '/report';
  }

  public all(): Promise<any> {
    const token = SessionService.getToken();

    return lastValueFrom(this.http.get<any>(this.baseUrl + '/?token=' + token))
      .then(data => data);
  }

  public combobox(id: number | string | boolean, kind: string): Promise<any> {
    const token: string = SessionService.getToken();

    return lastValueFrom(this.http.get<any>(this.baseUrl + '/' + id + '/combobox/' + kind + '?token=' + token))
      .then(data => data);
  }

  public get(id: number | string | boolean, start: Date, end: Date, filters?: string, first: number = 0, rows: number = 5, sorts?: string): Promise<any> {
    const ss: string = DateService.date(start);
    const se: string = DateService.date(end);

    const token: string = SessionService.getToken();
    let url: string = this.baseUrl + '/' + id + '?start=' + ss + '&end=' + se + '&token=' + token;

    if ((filters !== undefined) && (filters !== null) && (filters.length > 0)) //
      url += '&filters=' + filters;

    if ((first !== undefined) && (first !== null) && (first > 0)) //
      url += '&first=' + first;

    if ((rows !== undefined) && (rows !== null) && (rows > 0)) //
      url += '&rows=' + rows;

    if ((sorts !== undefined) && (sorts !== null) && (sorts.length > 0)) //
      url += '&sorts=' + sorts;

    return lastValueFrom(this.http.get<any>(url))
      .then(data => {
        // console.log(data);

        // TODO: Remove when Reason is working to rating
        if ((id === '013') || (id === '014')) //
          // TODO: Is removing Reason column
          data.cols.pop();

        return data;
      });
  }

}
