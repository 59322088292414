import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() {
  }

  public static getToken(): string {
    try {
      return window.sessionStorage.getItem('token') ?? 'null';
    } catch (e) {
      // Do nothing.
    }

    return 'null';
  }

  public static setToken(value: any): void {
    try {
      if (value === null) {
        window.sessionStorage.removeItem('token');
      } else {
        window.sessionStorage.setItem('token', value);
      }
    } catch (e) {
      // Do nothing.
    }
  }

  public static getAction(): string | null {
    try {
      return window.sessionStorage.getItem('action') ?? null;
    } catch (e) {
      // Do nothing.
    }

    return null;
  }

  public static setAction(value: any): void {
    try {
      if (value === null) {
        window.sessionStorage.removeItem('action');
      } else {
        window.sessionStorage.setItem('action', value);
      }
    } catch (e) {
      // Do nothing.
    }
  }

}
