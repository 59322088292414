import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import Cropper from 'cropperjs';

export interface ImageCropperSetting {
  width: number;
  height: number;
}

export interface ImageCropperResult {
  imageData: Cropper.ImageData;
  cropData: Cropper.CropBoxData;
  blob?: Blob;
  dataUrl?: string;
}

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CropperComponent implements OnInit, OnDestroy {

  @ViewChild('image') image: ElementRef | undefined;

  @Input() imageUrl: any;
  @Input() settings: ImageCropperSetting | undefined;
  @Input() cropbox: Cropper.CropBoxData | undefined;
  @Input() loadImageErrorText: string | undefined;
  @Input() cropperOptions: any = {};

  @Output() export: EventEmitter<ImageCropperResult> = new EventEmitter<ImageCropperResult>();
  @Output() ready: EventEmitter<any> = new EventEmitter();

  public isLoading: boolean = true;
  public cropper: Cropper | undefined | null;
  public imageElement: HTMLImageElement | undefined;
  public loadError: any;

  constructor() {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }
  }

  /**
   * Image loaded.
   *
   * @param ev
   */
  public imageLoaded(ev: Event): void {
    //
    // Unset load error state
    this.loadError = false;

    //
    // Setup image element
    const image: HTMLImageElement = ev.target as HTMLImageElement;
    this.imageElement = image;

    //
    // Add crossOrigin?
    if (this.cropperOptions.checkCrossOrigin) //
      image.crossOrigin = 'anonymous'

    //
    // Image on ready event
    image.addEventListener('ready', (): void => {
      //
      // Emit ready
      this.ready.emit(true);

      //
      // Unset loading state
      this.isLoading = false;

      //
      // Validate cropbox existance
      if (this.cropbox) //
        //
        // Set cropbox data
        this.cropper!.setCropBoxData(this.cropbox);
    });

    //
    // Setup aspect ratio according to settings
    let aspectRatio: number = NaN;
    if (this.settings) {
      const {width, height} = this.settings;
      aspectRatio = width / height;
    }

    //
    // Set crop options
    // extend default with custom config
    this.cropperOptions = Object.assign(
      {
        aspectRatio,
        movable: false,
        scalable: false,
        zoomable: false,
        viewMode: 1,
        checkCrossOrigin: true,
      },
      this.cropperOptions
    );

    //
    // Set cropperjs
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = undefined;
    }
    this.cropper = new Cropper(image, this.cropperOptions);
  }

  /**
   * Image load error.
   *
   * @param event
   */
  public imageLoadError(event: any): void {
    //
    // Set load error state
    this.loadError = true;

    //
    // Unset loading state
    this.isLoading = false;
  }

  /**
   * Export canvas.
   *
   * @param base64
   */
  public exportCanvas(base64?: any): void {
    if (!this.cropper) //
      return;

    //
    // Get and set image, crop and canvas data
    const imageData: Cropper.ImageData = this.cropper.getImageData();
    const cropData: Cropper.CropBoxData = this.cropper.getCropBoxData();
    const canvas: HTMLCanvasElement = this.cropper.getCroppedCanvas();
    const data: { imageData: Cropper.ImageData, cropData: Cropper.CropBoxData } = {imageData, cropData};

    //
    // Create promise to resolve canvas data
    const promise: Promise<any> = new Promise((resolve): void => {
      //
      // Validate base64
      if (base64) {
        //
        // Resolve promise with dataUrl
        return resolve({
          dataUrl: canvas.toDataURL('image/png'),
        });
      }
      canvas.toBlob((blob: Blob | null) => resolve({blob}));
    });

    //
    // Emit export data when promise is ready
    promise.then((res): void => this.export.emit(Object.assign(data, res)));
  }

}
