import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportFilterReport005Service {

  constructor() {
  }

  static generate(filters: any, afilters: any[]) {
    if (filters.order !== undefined) //
      afilters.push({
        field: 'pedido',
        value: filters.order,
        matchMode: 'equals'
      });

    if (filters.distributionCenter !== undefined) //
      afilters.push({
        field: 'centroDistribuicao',
        value: filters.distributionCenter,
        matchMode: 'startsWith'
      });

    if (filters.place !== undefined) //
      afilters.push({
        field: 'localEntrega',
        value: filters.place,
        matchMode: 'startsWith'
      });

    if (filters.state !== undefined) //
      afilters.push({
        field: 'uf',
        value: filters.state,
        matchMode: 'startsWith'
      });

    if (filters.status !== undefined) //
      afilters.push({
        field: 'situacao',
        value: filters.status,
        matchMode: 'startsWith'
      });
  }

}
