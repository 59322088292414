<div class="font-medium text-3xl text-900 mb-3 title cursor-default">Relatórios</div>

<div class="surface-card p-4 shadow-2 border-round">
  <!--
  <div class="font-medium text-3xl text-900 mb-3 title">Movie Information</div>
  <div class="text-500 mb-5">Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum.</div>
  -->

  <div class="p-fluid grid formgrid">
    <div class="field col-12 md:col-3">
      <label for="report">Nome</label>
      <p-dropdown id="report" [options]="reports" [(ngModel)]="selectedReport"
                  (ngModelChange)="onChangeReport($event)"
                  placeholder="Selecione um relatório" optionLabel="label" [filter]="true"
                  filterBy="label" [autofocus]="true"></p-dropdown>
    </div>
    <div class="field col-12 md:col-3">
      <label for="report-kind">Tipo do relatório</label>
      <p-dropdown id="report-kind" [options]="reportKinds" [(ngModel)]="selectedReportKind"
                  (ngModelChange)="onChangeReportKind($event)"
                  placeholder="Selecione um tipo" optionLabel="label" [filter]="true"
                  filterBy="label"></p-dropdown>
    </div>
    <div class="field col-12 md:col-3">
      <label for="start">Período - Início</label>
      <p-calendar inputId="start" [(ngModel)]="start"></p-calendar>
    </div>
    <div class="field col-12 md:col-3">
      <label for="end">Fim</label>
      <p-calendar inputId="end" [(ngModel)]="end"></p-calendar>
    </div>
  </div>

  <!-- 001: Peças - Geral -->
  <app-report-filter-report001 *ngIf="isReportKind('001')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report001>

  <!-- 002: Peças - Expurgo -->
  <app-report-filter-report002 *ngIf="isReportKind('002')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report002>

  <!-- 003: Peças - Avise-me -->
  <app-report-filter-report003 *ngIf="isReportKind('003')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report003>

  <!-- 004: Peças - Preços -->
  <app-report-filter-report004 *ngIf="isReportKind('004')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report004>

  <!-- 005: Pedidos - Geral -->
  <app-report-filter-report005 *ngIf="isReportKind('005')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report005>

  <!-- 006: Pedidos - Cancelados -->
  <app-report-filter-report006 *ngIf="isReportKind('006')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report006>

  <!-- 007: Pedidos - Pesquisa de Avaliação -->
  <app-report-filter-report007 *ngIf="isReportKind('007')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report007>

  <!-- 008: Pedidos - Pesquisa de Avaliação (Reprovado) -->
  <app-report-filter-report008 *ngIf="isReportKind('008')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report008>

  <!-- 009: Pedidos - Caixas -->
  <app-report-filter-report009 *ngIf="isReportKind('009')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report009>

  <!-- 010: Usuários - Geral -->
  <app-report-filter-report010 *ngIf="isReportKind('010')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report010>

  <!-- 011: Fale Conosco - Geral -->
  <app-report-filter-report011 *ngIf="isReportKind('011')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report011>

  <!-- 012: Peças > Estoque -->
  <app-report-filter-report012 *ngIf="isReportKind('012')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report012>

  <!-- 013: Pedidos - Avaliação -->
  <app-report-filter-report013 *ngIf="isReportKind('013')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report013>

  <!-- 014: Peças - Avaliação -->
  <app-report-filter-report014 *ngIf="isReportKind('014')" [(data)]="filters" [(options)]="comboOptions"
                               [(loading)]="isLoadingComboOptions"></app-report-filter-report014>

  <div class="flex flex-row-reverse mb-2">
    <button pButton type="button" label="Filtrar" icon="fa-solid fa-filter" class="inline-flex" (click)="filter()"
            [disabled]="isLoadingComboOptions || isLoading"></button>
    <button pButton type="button" label="Limpar" icon="fa-solid fa-times" class="mr-2 p-button-danger inline-flex"
            (click)="clearFilter()" [disabled]="isLoadingComboOptions || isLoading"></button>
  </div>

  <div *ngIf="isLoadingComboOptions" class="text-center mb-2">
    Carregando as opções disponíveis...
  </div>

  <div *ngIf="!isLoadingComboOptions && !isLoading && !isFilterTouched" class="text-center">
    Forneça os parâmetros e clique no botão <strong>Filtrar</strong>.
  </div>

  <p-progressBar mode="indeterminate" *ngIf="(isLoadingComboOptions) || (isLoading && isFilterTouched)"></p-progressBar>

  <ng-container *ngIf="!isLoadingComboOptions && !isLoading && isFilterTouched">

    <div *ngIf="records.length === 0" class="text-center">
      Nenhum resultado encontrado.
    </div>

    <p-table *ngIf="records.length > 0" [columns]="columns" [value]="records"
             [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
             responsiveLayout="scroll"
             currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} resultado(s)"
             [rowsPerPageOptions]="[5,10,25,50]"
             styleClass="p-datatable-striped"
             [(selection)]="selectedRecords">
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between">
          <div *ngIf="records.length > 0" class="mt-2 table-total">
            {{ records.length }} resultado(s) encontrado(s).
          </div>
          <div>
            <button type="button" pButton pRipple icon="fa-solid fa-file-excel" (click)="exportSpreadsheet()"
                    class="p-button-success ml-auto mr-2" pTooltip="Exportar para planilha"
                    tooltipPosition="bottom" [disabled]="selectedRecords.length === 0"></button>
            <button type="button" pButton pRipple icon="fa-solid fa-file-pdf" (click)="exportDocument()"
                    class="p-button-warning ml-auto" pTooltip="Exportar para documento"
                    tooltipPosition="bottom" [disabled]="selectedRecords.length === 0"></button>
            <p-dialog header="Exportar para documento" [(visible)]="displayExportDocumentReportTitle"
                      [style]="{width: '50vw'}" [modal]="true">
              <div class="field">
                <label for="report-title">Forneça o título do relatório</label>
                <input id="report-title" type="text" pInputText [(ngModel)]="reportTitle"
                       class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
              </div>
              <ng-template pTemplate="footer">
                <p-button icon="fa-solid fa-times" (click)="displayExportDocumentReportTitle=false" label="Cancelar"
                          styleClass="p-button-text p-button-danger"></p-button>
                <p-button icon="fa-solid fa-check" (click)="exportDocumentWithTitle()" label="Continuar"></p-button>
              </ng-template>
            </p-dialog>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="column-checkbox">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td>
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns">
            {{ rowData[col.field] }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>

</div>
