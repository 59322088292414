import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {SessionService} from '../session/session.service';
import {lastValueFrom} from 'rxjs';
import {Answerable} from '../../models/answerable';

@Injectable({
  providedIn: 'root'
})
export class AnswerableService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl + '/register/answerable';
  }

  public all(term: string | null = null, combobox: boolean | null = null): Promise<Answerable[]> {
    const url: string = this.baseUrl + '/?token=' + SessionService.getToken() + '&combobox=' + combobox + //
      '&term=' + term;
    const encoded: string = encodeURI(url);

    return lastValueFrom(this.http.get<any>(encoded))
      .then(res => <Answerable[]>res)
      .then((data: Answerable[]): Answerable[] => data);
  }

  public add(answerable: Answerable): Promise<any> {
    const url: string = this.baseUrl + '/?token=' + SessionService.getToken();

    return lastValueFrom(this.http.post<any>(url, answerable))
      // .then(res => res.added)
      .then(data => data);
  }

}
