import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {SessionService} from '../session/session.service';
import {lastValueFrom} from 'rxjs';
import {Supplier} from '../../models/supplier';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl + '/register/supplier';
  }

  public all(term: string | null = null): Promise<Supplier[]> {
    const url: string = this.baseUrl + '/?token=' + SessionService.getToken() + '&combobox=true&term=' + term;
    const encoded: string = encodeURI(url);

    return lastValueFrom(this.http.get<any>(encoded))
      .then(res => <Supplier[]>res)
      .then((data: Supplier[]): Supplier[] => data);
  }

  public add(supplier: Supplier): Promise<any> {
    const url: string = this.baseUrl + '/?token=' + SessionService.getToken();

    return lastValueFrom(this.http.post<any>(url, supplier))
      // .then(res => res.added)
      .then(data => data);
  }

}
