<div class="flex justify-content-between">
  <div class="font-medium text-3xl text-blue-800 mb-3 title cursor-default">
    Gerenciar peças
  </div>
</div>

<div class="surface-card"> <!-- p-4 shadow-2 border-round -->
  <!--
  <div class="font-medium text-3xl text-900 mb-3 title">Movie Information</div>
  <div class="text-500 mb-5">Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum.</div>
  -->

  <div class="bg-card border-round-sm pt-3 mb-4">
    <div class="p-fluid p-3 grid formgrid">
      <div class="pr-5 pl-5 mb-5 col-12 md:col-3">
        <label class="pb-2 block label-color" for="code">Código</label>
        <input id="code" type="text" pInputText [(ngModel)]="code" autofocus/>
      </div>
      <div class="pr-5 pl-5 mb-5 col-12 md:col-3">
        <label class="pb-2 block" for="name">Nome</label>
        <input id="name" type="text" pInputText [(ngModel)]="name"/>
      </div>
      <div class="pr-5 pl-5 mb-5 col-12 md:col-3">
        <label class="pb-2 block label-color" for="communication">Comunicação</label>
        <p-dropdown
          id="communication"
          [options]="communications"
          [(ngModel)]="communication"
          placeholder="Selecione uma comunicação"
          optionLabel="label"
          [filter]="true"
          [showClear]="true"
          filterBy="label"
        ></p-dropdown>
      </div>
      <div class="pr-5 pl-5 mb-5 col-12 md:col-3">
        <label class="pb-2 block label-color" for="kind">Tipo</label>
        <p-dropdown
          id="kind"
          [options]="kinds"
          [(ngModel)]="kind"
          placeholder="Selecione um tipo"
          optionLabel="label"
          [filter]="true"
          [showClear]="true"
          filterBy="label"
        ></p-dropdown>
      </div>
      <div class="pr-5 pl-5 mb-5 col-12 md:col-3">
        <label class="pb-2 block label-color" for="availability">Disponibilidade</label>
        <p-dropdown
          id="availability"
          [options]="availabilities"
          [(ngModel)]="availability"
          placeholder="Selecione uma disponibilidade"
          optionLabel="label"
          [filter]="true"
          [showClear]="true"
          filterBy="label"
        ></p-dropdown>
      </div>
      <div class="pr-5 pl-5 mb-5 col-12 md:col-3">
        <label class="pb-2 block label-color" for="status">Situação</label>
        <p-dropdown
          id="status"
          [options]="statuses"
          [(ngModel)]="status"
          placeholder="Selecione um tipo"
          optionLabel="label"
          [filter]="true"
          [showClear]="true"
          filterBy="label"
        ></p-dropdown>
      </div>
      <div class="pr-5 pl-5 mb-5 col-12 md:col-3">
        <label class="pb-2 block label-color" for="start">Atualização - Início</label>
        <p-calendar inputId="start" [(ngModel)]="start"></p-calendar>
      </div>
      <div class="pr-5 pl-5 mb-5 col-12 md:col-3">
        <label class="pb-2 block label-color" for="end">Fim</label>
        <p-calendar inputId="end" [(ngModel)]="end"></p-calendar>
      </div>
    </div>
  </div>
  <div class="flex items-center justify-content-between">
    <div>
      <button
        pButton
        type="button"
        label="Cadastrar peça"
        icon="fa-solid fa-plus"
        class="inline-flex btn-primary uppercase"
        (click)="add()"
        [disabled]="isLoading"
      ></button>
    </div>
    <div class="flex flex-row-reverse mb-2">
      <button
        pButton
        type="button"
        label="Filtrar"
        class="inline-flex btn-primary"
        icon="fa-solid fa-filter"
        (click)="filter()"
        [disabled]="isLoading"
      ></button>
      <button
        pButton
        type="button"
        label="Limpar"
        class="mr-2 p-button-danger inline-flex"
        icon="fa-solid fa-times"
        (click)="clearFilter()"
        [disabled]="isLoading"
      ></button>
    </div>
  </div>

  <div *ngIf="!isLoading && !isFilterTouched" class="text-center">
    Forneça os parâmetros e clique no botão <strong>Filtrar</strong>.
  </div>

  <p-progressBar mode="indeterminate" *ngIf="isLoading"></p-progressBar>

  <ng-container *ngIf="!isLoading && isFilterTouched">
    <div *ngIf="total === 0" class="text-center">
      Nenhum resultado encontrado.
    </div>

    <!--    <p-table *ngIf="total > 0" [columns]="columns" [value]="records"-->
    <!--             [paginator]="true" [rows]="5" [totalRecords]="total" [showCurrentPageReport]="true"-->
    <!--             responsiveLayout="scroll"-->
    <!--             currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} resultado(s)"-->
    <!--             [rowsPerPageOptions]="[5,10,25,50]"-->
    <!--             styleClass="p-datatable-striped">-->
    <!-- *ngIf="total > 0" -->
    <ng-container *ngIf="total > 0">
      <p-table
        [value]="records"
        responsiveLayout="scroll"
        [lazy]="true"
        [lazyLoadOnInit]="true"
        (onLazyLoad)="load($event)"
        dataKey="id"
        [paginator]="true"
        [rows]="5"
        [totalRecords]="total"
        [loading]="isLoading"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} resultado(s)"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
        styleClass="p-datatable-striped"
        sortField="updatedAt"
        [sortOrder]="-1"
      >
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between">
            <div *ngIf="total > 0" class="mt-2">
              <span class="table-total"
              >{{ total }} resultado(s) encontrado(s).</span
              >
              <div class="legend cursor-default">
                <div class="label">Legenda:</div>
                <div class="item expiring">
                  <div class="color"></div>
                  <div>A vencer</div>
                </div>
                <div class="item draft">
                  <div class="color"></div>
                  <div>Rascunho</div>
                </div>
                <div class="item expired">
                  <div class="color"></div>
                  <div>Vencida</div>
                </div>
                <div class="item valid">
                  <div class="color"></div>
                  <div>Válida</div>
                </div>
              </div>
            </div>
            <div>
              <!--
              <button type="button" pButton pRipple icon="fa-solid fa-file-excel" (click)="exportSpreadsheet()"
                      class="p-button-success ml-auto mr-2" pTooltip="Exportar para planilha"
                      tooltipPosition="bottom" [disabled]="selectedRecords.length === 0"></button>
              <button type="button" pButton pRipple icon="fa-solid fa-file-pdf" (click)="exportDocument()"
                      class="p-button-warning ml-auto" pTooltip="Exportar para documento"
                      tooltipPosition="bottom" [disabled]="selectedRecords.length === 0"></button>
              <p-dialog header="Exportar para documento" [(visible)]="displayExportDocumentReportTitle"
                        [style]="{width: '50vw'}" [modal]="true">
                <div class="field">
                  <label for="report-title">Forneça o título do relatório</label>
                  <input id="report-title" type="text" pInputText [(ngModel)]="reportTitle"
                         class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <ng-template pTemplate="footer">
                  <p-button icon="fa-solid fa-times" (click)="displayExportDocumentReportTitle=false" label="Cancelar"
                            styleClass="p-button-text p-button-danger"></p-button>
                  <p-button icon="fa-solid fa-check" (click)="exportDocumentWithTitle()" label="Continuar"></p-button>
                </ng-template>
              </p-dialog>
              -->
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="code">
              Código
              <p-sortIcon field="code"></p-sortIcon>
            </th>
            <th>Miniatura</th>

            <!--
            <th pSortableColumn="name">Nome
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            -->
            <th pSortableColumn="communication">
              Ação e comunicação
              <p-sortIcon field="communication"></p-sortIcon>
            </th>
            <th pSortableColumn="kind">
              Tipo de peça
              <p-sortIcon field="kind"></p-sortIcon>
            </th>
            <th pSortableColumn="availability">
              Disponibilidade
              <p-sortIcon field="availability"></p-sortIcon>
            </th>
            <th pSortableColumn="status">
              Situação
              <p-sortIcon field="status"></p-sortIcon>
            </th>
            <th pSortableColumn="updatedAt">
              Última atualização
              <p-sortIcon field="updatedAt"></p-sortIcon>
            </th>
            <th class="text-center">Ação</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr
            [class.draft]="item.statusId == '100'"
            [class.valid]="item.statusId == '101'"
            [class.expiring]="item.statusId == '102'"
            [class.expired]="item.statusId == '103'"
          >
            <td class="cursor-default">{{ item.code }}</td>
            <td>
              <p-image
                *ngIf="item.thumb"
                [src]="item.thumb"
                [alt]="item.name"
                width="60"
                height="60"
                class="shadow-4"
                [preview]="true"
                [pTooltip]="item.name"
                tooltipPosition="right"
              ></p-image>
              <img
                *ngIf="!item.thumb"
                src="../../../../assets/images/blank-item.svg"
                [alt]="item.name"
                width="60"
                height="60"
                class="shadow-4"
                [pTooltip]="item.name + '\n*Sem miniatura'"
                tooltipPosition="right"
              />
            </td>
            <!--
            <td class="cursor-default" [pTooltip]="item.name" tooltipPosition="right"><span
              class="field-limit field-name">{{ item.name }}</span></td>
            -->
            <td
              class="cursor-default"
              [pTooltip]="item.communication"
              tooltipPosition="right"
            >
              <span class="field-limit field-communication">{{
                item.communication
                }}</span>
            </td>
            <td class="cursor-default">{{ item.kind }}</td>
            <td class="cursor-default">{{ item.availability }}</td>
            <td class="cursor-default">{{ item.status }}</td>
            <td class="cursor-default">{{ item.updatedAt }}</td>
            <td class="text-center">
              <!--
              <button [routerLink]="['/register/product/', item.id]" pButton pRipple type="button"
                      icon="fa-solid fa-pencil"
                      class="p-button-rounded p-button-info mr-3" pTooltip="Editar" tooltipPosition="left"></button>
              <button (click)="delete($event, item)" pButton pRipple type="button" icon="fa-solid fa-trash"
                      class="p-button-rounded p-button-danger" pTooltip="Remover" tooltipPosition="left"></button>
              -->
              <!--
              <button (click)="delete($event, item)" pButton pRipple type="button" icon="fa-solid fa-bars"
                      class="p-button-rounded p-button-text p-button-plain" pTooltip="Opções" tooltipPosition="left"></button>
              -->
              <!-- label="Editar" -->
              <p-splitButton
                icon="fa-solid fa-pencil"
                (onClick)="edit($event, item)"
                styleClass="p-button-sm"
                (onDropdownClick)="onOpenMenu($event, item)"
                [model]="options"
              ></p-splitButton>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-container>
  </ng-container>
</div>
