import {Injectable} from '@angular/core';
import {ReportFilterFieldService} from '../../report-filter-field/report-filter-field.service';

@Injectable({
  providedIn: 'root'
})
export class ReportFilterReport012Service {

  constructor() {
  }

  static generate(filters: any, afilters: any[]) {
    if (filters.id !== undefined) //
      afilters.push({
        field: 'id',
        value: '' + filters.id,
        matchMode: 'equals'
      });

    ReportFilterFieldService.hasCode(filters, afilters);
    ReportFilterFieldService.hasKind(filters, afilters);

    if (filters.status !== undefined) //
      afilters.push({
        field: 'situacao',
        value: '' + filters.status,
        matchMode: 'startsWith'
      });

    ReportFilterFieldService.hasAdvertising(filters, afilters);

    if (filters.demandant !== undefined) //
      afilters.push({
        field: 'areaDemandante',
        value: '' + filters.demandant,
        matchMode: 'startsWith'
      });

    ReportFilterFieldService.hasInventory(filters, afilters);
  }

}
