import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor() {
  }

  public static detectQuality(width: number, height: number): string | null {
    // HD 1280 x 720
    if (width === 1280 && height === 720) //
      return 'HD';

    // Full HD 1920 x 1080
    if (width === 1920 && height === 1080) //
      return 'Full HD';

    // 2K 2048 x 1080
    if (width === 2048 && height === 1080) //
      return '2K';

    // 4K 3840 x 2160
    if (width === 3840 && height === 2160) //
      return 'Ultra HD (4K)';

    // 8K 7680 x 4320
    if (width === 7680 && height === 4320) //
      return '8K';

    // 10K 10240 x 4320
    if (width === 10240 && height === 4320) //
      return '10K';

    return null;
  }

  public static detectAspectRatio(width: number, height: number): string | null {
    if (width === 0 || height === 0) //
      // throw new IllegalArgumentException("Cannot calculate aspect ratio from zero");
      return null;


    if (width < 0 || height < 0) //
      // throw new IllegalArgumentException("Cannot calculate aspect ratio from negative numbers");
      return null;

    return VideoService.reduceRatio(width, height);
  }

  /**
   * Reduce a numerator and denominator to it's smallest, integer ratio using Euclid's Algorithm.
   */
  private static reduceRatio(numerator: number, denominator: number): string {
    let temp: number = 0;
    let left: number = 0;
    let right: number = 0;

    // From: http://pages.pacificcoast.net/~cazelais/euclid.html
    const gcd = (a: number, b: number): number => //
      (b === 0) ? a : gcd(b, a % b);

    // Take care of the simple case
    if (numerator === denominator) //
      return '1:1';

    // Make sure numerator is always the larger number
    if (+numerator < +denominator) {
      temp = numerator;
      numerator = denominator;
      denominator = temp;
    }

    const divisor: number = gcd(+numerator, +denominator);

    if ('undefined' === typeof temp) {
      left = numerator / divisor;
      right = denominator / divisor;
    } else {
      left = denominator / divisor;
      right = numerator / divisor;
    }

    // Handle special cases
    if (8 === left && 5 === right) {
      left = 16;
      right = 10;
    }

    return `${left}:${right}`;
  }

}
