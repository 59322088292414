import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor() {
  }

  public static getDuration(media: any): string {
    //let duration: string = '00:00:00';
    let duration: string = '00:00';

    try {
      let seconds: number = parseInt(media.duration, 10);
      let minutes: number = Math.trunc(seconds / 60);
      seconds = seconds - (minutes * 60);

      // let hours: number = 0;
      // if (minutes > 60) {
      //   hours = Math.trunc(minutes / 60);
      //   minutes = minutes - (hours * 60);
      // }

      // let shours: string = '' + hours;
      // while (shours.length < 2) shours = '0' + shours;

      let sminutes: string = '' + minutes;
      while (sminutes.length < 2) sminutes = '0' + sminutes;

      let sseconds: string = '' + seconds;
      while (sseconds.length < 2) sseconds = '0' + sseconds;

      // duration = shours + ':' + sminutes + ':' + sseconds;
      duration = sminutes + ':' + sseconds;
    } catch (e) {
      // Do nothing.
    }

    return duration;
  }

}
