<div class="flex justify-content-between">
  <div class="font-medium text-3xl text-900 mb-3 title cursor-default">Gerenciar ações de comunicação</div>
  <div>
    <button pButton type="button" label="Adicionar" icon="fa-solid fa-plus" class="inline-flex" (click)="add()"
            [disabled]="isLoading"></button>
  </div>
</div>

<div class="surface-card p-4 shadow-2 border-round">
  <!--
  <div class="font-medium text-3xl text-900 mb-3 title">Movie Information</div>
  <div class="text-500 mb-5">Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum.</div>
  -->

  <div class="card-action">
    <!-- p-button-sm -->
    <button pButton pRipple label="Carregar novamente" icon="fa-solid fa-refresh" class=""
            (click)="reload()" [loading]="isReloading" [disabled]="isRemoving || isSaving"></button>&nbsp;&nbsp;
    <button pButton pRipple label="Remover ordenação" icon="fa-solid fa-times" class="p-button-warning"
            (click)="remove()" [loading]="isRemoving" [disabled]="isReloading || isSaving"></button>&nbsp;&nbsp;
    <button pButton pRipple label="Salvar ordenação" icon="fa-solid fa-check" class="p-button-danger" (click)="save()"
            [loading]="isSaving" [disabled]="isReloading || isRemoving"></button>
  </div>

  <div aria-live="polite" class="p-inline-message p-component p-inline-message-warn">
    <span class="p-inline-message-icon fa-solid fa-exclamation-triangle"></span>
    <span
      class="p-inline-message-text ng-star-inserted">É exibido somente <strong>os primeiros 9 itens</strong>.</span>
  </div>

  <p-progressBar mode="indeterminate" *ngIf="isLoading"></p-progressBar>

  <ng-container *ngIf="!isLoading">

    <p-blockUI [blocked]="isReloading || isRemoving || isSaving" [target]="tbl" styleClass="blockui-white">
      <i class="fa-solid fa-spin pi-sync blockui-lock" *ngIf="isReloading || isRemoving"></i>
      <i class="fa-solid fa-spin pi-spinner blockui-lock" *ngIf="isSaving"></i>
    </p-blockUI>

    <p-table #tbl [value]="items" [columns]="cols" [reorderableColumns]="false" responsiveLayout="scroll"
             (onRowReorder)="onRowReorder($event)" styleClass="p-datatable-striped">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width:3rem"></th>
          <th *ngFor="let col of columns" [class.text-center]="col.centered"><!-- pReorderableColumn -->
            {{ col.header }}
          </th>
          <th class="text-center">Ação</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-columns="columns" let-index="rowIndex">
        <tr [pReorderableRow]="index" [class.limit]="index === 8">
          <td>
            <span class="fa-solid fa-bars" pReorderableRowHandle></span>
          </td>
          <td *ngFor="let col of columns" pReorderableRowHandle>
            <ng-container *ngIf="col.field !== 'image'">
              {{ item[col.field] }}
            </ng-container>

            <ng-container *ngIf="col.field === 'image'">
              <p-image [src]="item[col.field]" [alt]="item['name']" width="60" height="60" class="shadow-4"
                       [preview]="true"></p-image>
            </ng-container>
          </td>
          <td>
            <!--
            <button pButton pRipple type="button" icon="fa-solid fa-pencil"
                    class="p-button-rounded p-button-info" pTooltip="Editar" tooltipPosition="left"></button>
            -->
            <!-- label="Editar" -->
            <p-splitButton icon="fa-solid fa-pencil" (onClick)="edit($event, item)"
                           styleClass="p-button-sm"
                           (onDropdownClick)="onOpenMenu($event, item)" [model]="options"></p-splitButton>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="p-d-flex p-ai-center p-jc-between">
          Existe um total de {{ items ? items.length : 0 }} destaques.
        </div>
      </ng-template>
    </p-table>

  </ng-container>
</div>
