import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-report-filter-report003',
  templateUrl: './report-filter-report003.component.html',
  styleUrls: ['./report-filter-report003.component.scss']
})
export class ReportFilterReport003Component implements OnInit {

  filters: any = {};
  comboOptions: any;
  isLoadingComboOptions: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  // Attribute: data

  @Output()
  dataChange = new EventEmitter<any>();

  @Input()
  get data() {
    return this.filters;
  }

  set data(value) {
    this.filters = value;
    this.dataChange.emit(this.filters);
  }

  // Attribute: options

  @Output()
  optionsChange = new EventEmitter<any>();

  @Input()
  get options() {
    return this.comboOptions;
  }

  set options(value: any) {
    this.comboOptions = value;
    this.optionsChange.emit(this.comboOptions);
  }

  // Attribute: loading

  @Output()
  loadingChange = new EventEmitter<boolean>();

  @Input()
  get loading() {
    return this.isLoadingComboOptions;
  }

  set loading(value) {
    this.isLoadingComboOptions = value;
    this.loadingChange.emit(this.isLoadingComboOptions);
  }

}
