import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {RegisterCommunicationComponent} from './pages/register/register-communication/register-communication.component';
import {
  RegisterCommunicationNewComponent
} from './pages/register/register-communication-new/register-communication-new.component';
import {
  RegisterCommunicationEditComponent
} from './pages/register/register-communication-edit/register-communication-edit.component';
import {RegisterProductComponent} from './pages/register/register-product/register-product.component';
import {RegisterProductEditComponent} from './pages/register/register-product-edit/register-product-edit.component';
import {ReportComponent} from './pages/report/report.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'register/communication', component: RegisterCommunicationComponent},
  {path: 'register/communication/new', component: RegisterCommunicationNewComponent},
  {path: 'register/communication/:id', component: RegisterCommunicationEditComponent},
  {path: 'register/product', component: RegisterProductComponent},
  {path: 'register/product/new', component: RegisterProductEditComponent},
  {path: 'register/product/:id', component: RegisterProductEditComponent},
  {path: 'report', component: ReportComponent},
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
