<div class="flex justify-content-between">
  <div class="font-medium text-3xl text-900 mb-3 title cursor-default">Adicionar ação de comunicação</div>
  <div>
    <button pButton type="button" label="Cancelar" icon="fa-solid fa-times" class="inline-flex p-button-danger"
            (click)="cancel()"
            [disabled]="isLoading"></button>
    <button pButton type="button" label="Salvar" icon="fa-solid fa-check" class="ml-2 inline-flex" (click)="confirm()"
            [disabled]="isLoading"></button>
  </div>
</div>

<div class="surface-card p-4 shadow-2 border-round">
  <!--
  <p-progressBar [value]="uploadProgress" *ngIf="(processing) && (uploadProgress > 0)"
                 styleClass="mb-4"></p-progressBar>
  -->

  <div class="p-fluid grid formgrid">

    <div class="field col-12 md:col-12">
      <label for="name" class="required">Nome</label>
      <input id="name" #nameInput type="text" pInputText maxlength="300" [(ngModel)]="name" [disabled]="isLoading">
      <app-characters-remaining [field]="nameInput" [value]="name"></app-characters-remaining>
    </div>

    <div class="field col-12 md:col-12">
      <!-- <label for="image" class="required">Imagem</label> -->
      <p-fileUpload id="image" #imageInput name="image[]" mode="basic" [chooseLabel]="fileUploadChooseLabel"
                    accept=".jpg,.png,.gif, .jpeg" [customUpload]="true" (uploadHandler)="onFileUpload($event)"
                    [auto]="true"></p-fileUpload>
    </div>

    <div class="col-12 md:col-12" *ngIf="canCropImage">
      <div class="grid">
        <div class="col-12 md:col-8 img-cropper">
          <span class="cursor-default filename-limit">{{ fileName }}</span>
          <image-cropper
            class="mt-2"
            [imageBase64]="imageBase64"
            [maintainAspectRatio]="true"
            [aspectRatio]="4 / 4"
            format="png"
            [resizeToWidth]="480"
            [resizeToHeight]="480"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperImageReady()"
            (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
        </div>
        <div class="col-12 md:col-4 cropped-image">
          <span class="cursor-default">Pré-visualização</span>
          <img [src]="croppedImage"/>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex justify-content-between mt-2">
  <div></div>
  <div>
    <button pButton type="button" label="Cancelar" icon="fa-solid fa-times" class="inline-flex p-button-danger"
            (click)="cancel()"
            [disabled]="isLoading"></button>
    <button pButton type="button" label="Salvar" icon="fa-solid fa-check" class="ml-2 inline-flex" (click)="confirm()"
            [disabled]="isLoading"></button>
  </div>
</div>
