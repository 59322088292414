import {Injectable} from '@angular/core';
import {ReportFilterFieldService} from '../../report-filter-field/report-filter-field.service';

@Injectable({
  providedIn: 'root'
})
export class ReportFilterReport008Service {

  constructor() {
  }

  static generate(filters: any, afilters: any[]) {
    if (filters.order !== undefined) //
      afilters.push({
        field: 'pedido',
        value: filters.order,
        matchMode: 'equals'
      });

    ReportFilterFieldService.hasCode(filters, afilters);

    if (filters.owner !== undefined) //
      afilters.push({
        field: 'responsavel',
        value: filters.owner,
        matchMode: 'startsWith'
      });

    if (filters.enrollment !== undefined) //
      afilters.push({
        field: 'matricula',
        value: filters.enrollment,
        matchMode: 'startsWith'
      });

    if (filters.state !== undefined) //
      afilters.push({
        field: 'uf',
        value: filters.state,
        matchMode: 'startsWith'
      });

    if (filters.approved !== undefined) //
      afilters.push({
        field: 'aprovado',
        value: filters.approved,
        matchMode: 'startsWith'
      });

    if (filters.problem !== undefined) //
      afilters.push({
        field: 'problema',
        value: filters.problem,
        matchMode: 'startsWith'
      });

    if (filters.problemKind !== undefined) //
      afilters.push({
        field: 'problemaTipo',
        value: filters.problemKind,
        matchMode: 'startsWith'
      });

    if (filters.problemOther !== undefined) //
      afilters.push({
        field: 'problemaOutro',
        value: filters.problemOther,
        matchMode: 'startsWith'
      });

    if (filters.problemDetail !== undefined) //
      afilters.push({
        field: 'problemaDetalhe',
        value: filters.problemDetail,
        matchMode: 'startsWith'
      });
  }

}
