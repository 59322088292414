import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {SessionService} from '../session/session.service';
import {lastValueFrom} from 'rxjs';
import {Communication} from '../../models/communication';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl + '/register/communication';
  }

  public all(term: string | null = null, combobox: boolean | null = null): Promise<Communication[]> {
    const url: string = this.baseUrl + '/?token=' + SessionService.getToken() + '&combobox=' + combobox + //
      '&term=' + term;
    const encoded: string = encodeURI(url);

    return lastValueFrom(this.http.get<any>(encoded))
      .then(res => <Communication[]>res)
      .then((data: Communication[]): Communication[] => data);
  }

  public saverOrder(communication: number[]): Promise<boolean> {
    const url = this.baseUrl + '/order?token=' + SessionService.getToken();

    return lastValueFrom(this.http.post<any>(url, communication))
      .then(res => res.saved)
      .then(data => data);
  }

  public add(communication: Communication): Promise<any> {
    const url: string = this.baseUrl + '/?token=' + SessionService.getToken();

    return lastValueFrom(this.http.post<any>(url, communication))
      // .then(res => res.added)
      .then(data => data);
  }

  // public get(id: string): Observable<any> {
  //   const url = this.baseUrl + '/' + id + '?token=' + SessionService.getToken();
  //
  //   return this.http.get(url, {
  //     // headers: {
  //     //   'Authorization': 'Bearer ' + UserService.getToken()
  //     // }
  //   }).pipe(catchError(HttpErrorService.management));
  // }
  public get(id: string): Promise<any> { //Observable<any> {
    const url = this.baseUrl + '/' + id + '?token=' + SessionService.getToken();

    // return this.http.delete(url, {
    //   // headers: {
    //   //   'Authorization': 'Bearer ' + UserService.getToken()
    //   // }
    // }).pipe(catchError(HttpErrorService.management));
    return lastValueFrom(this.http.get<any>(url))
      .then(res => res)
      .then(data => data);
  }

  public update(id: string, communication: any): Promise<boolean> {
    const url = this.baseUrl + '/' + id + '?token=' + SessionService.getToken();

    return lastValueFrom(this.http.put<any>(url, communication))
      .then(res => res.updated)
      .then(data => data);
  }

  public delete(id: string): Promise<any> { //Observable<any> {
    const url = this.baseUrl + '/' + id + '?token=' + SessionService.getToken();

    // return this.http.delete(url, {
    //   // headers: {
    //   //   'Authorization': 'Bearer ' + UserService.getToken()
    //   // }
    // }).pipe(catchError(HttpErrorService.management));
    return lastValueFrom(this.http.delete<any>(url))
      .then(res => res.added)
      .then(data => data);
  }

}
