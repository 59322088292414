import {Component, OnInit} from '@angular/core';
import {ComboBoxOption} from '../../../models/combo-box-option';
import {ProductService} from '../../../services/product/product.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService, LazyLoadEvent, MenuItem, MessageService} from 'primeng/api';
import {environment} from '../../../../environments/environment';
import {TableService} from '../../../services/table/table.service';
import {ScrollService} from '../../../services/scroll/scroll.service';
import {SessionService} from '../../../services/session/session.service';

@Component({
  selector: 'app-register-product',
  templateUrl: './register-product.component.html',
  styleUrls: ['./register-product.component.scss']
})
export class RegisterProductComponent implements OnInit {

  code!: string | null;
  name!: string | null;
  communications!: ComboBoxOption[];
  communication!: ComboBoxOption | null;
  kinds!: ComboBoxOption[];
  kind!: ComboBoxOption | null;
  availabilities!: ComboBoxOption[];
  availability!: ComboBoxOption | null;
  statuses!: ComboBoxOption[];
  status!: ComboBoxOption | null;
  start!: Date;
  end!: Date;

  isLoading: boolean = false;
  isFilterTouched: boolean = false;
  lastLazyLoadEvent: LazyLoadEvent | undefined;

  columns!: any[];
  records: any[] = [];

  total: number = 0;
  pages: number = 0;

  item: any;
  options: MenuItem[] = [];

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
  }

  public ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params): void => {
      if (params['action']) {
        SessionService.setAction(params['action']);
      } else {
        SessionService.setAction(null);
      }

      if (environment.useToken) {
        if (params['token']) {
          SessionService.setToken(params['token']);
          this.init();
        } else {
          this.router.navigateByUrl('/login').then((): void => {
          });
        }
      } else {
        this.init();
      }
    });
  }

  private init(): void {
    ScrollService.toTop();

    this.productService.combobox('availability').then(data => this.availabilities = data);
    this.productService.combobox('status').then(data => this.statuses = data);
    this.productService.combobox('communication').then(data => this.communications = data);
    this.productService.combobox('kind').then(data => this.kinds = data);

    // // this.start = new Date();
    // // this.start.setMonth(this.start.getMonth() - 3);
    // this.start = new Date(2017, 0, 1);
    // this.end = new Date();

    this.columns = [
      {field: 'code', header: 'Código'},
      {field: 'name', header: 'Nome'},
      {field: 'communication', header: 'Comunicação'},
      {field: 'kind', header: 'Tipo'},
      {field: 'availability', header: 'Disponibilidade'},
      {field: 'status', header: 'Situação'},
      {field: 'updatedAt', header: 'Atualização'}
    ];

    this.clearFilter();
    this.filter();

    // Options
    this.options = [
      // {
      //   label: 'Reimprimir',
      //   icon: 'fa-solid fa-print',
      //   command: (event: any) => {
      //     // console.log(event, this.item);
      //     // this.delete(event, this.item);
      //   }
      // },
      {
        label: 'Duplicar',
        icon: 'fa-solid fa-copy',
        command: (event: any) => {
          // console.log(event, this.item);
          this.duplicate(event, this.item);
        }
      },
      // {
      //   label: 'Ativar',
      //   icon: 'fa-solid fa-eye',
      //   command: (event: any) => {
      //     // console.log(event, this.item);
      //     // this.delete(event, this.item);
      //   }
      // },
      // {
      //   label: 'Inativar',
      //   icon: 'fa-solid fa-eye-slash',
      //   command: (event: any) => {
      //     // console.log(event, this.item);
      //     // this.delete(event, this.item);
      //   }
      // },
      {
        label: 'Remover',
        icon: 'fa-solid fa-trash',
        command: (event: any) => {
          // console.log(event, this.item);
          this.delete(event, this.item);
        }
      }
    ];

    if (SessionService.getAction() === 'add') //
      this.add();
  }

  public load(event: LazyLoadEvent | undefined | null = null): void {
    if (this.start === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O início do período precisa ser fornecido'
      });
      return;
    }

    if (this.end === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O final do período precisa ser fornecido'
      });
      return;
    }

    if (this.start.getTime() > this.end.getTime()) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O início do período precisa ser menor ou igual o final'
      });
      return;
    }

    let filters: any[] = [];
    let filter!: string;

    if (this.code !== null) //
      filters.push({
        field: 'code',
        value: this.code,
        matchMode: 'startsWith'
      });

    if (this.name !== null) //
      filters.push({
        field: 'name',
        value: this.name,
        matchMode: 'startsWith'
      });

    if (this.communication !== null) //
      filters.push({
        field: 'communication',
        value: this.communication.value,
        matchMode: 'startsWith'
      });

    if (this.kind !== null) //
      filters.push({
        field: 'kind',
        value: this.kind.value,
        matchMode: 'startsWith'
      });

    if (this.availability !== null) //
      filters.push({
        field: 'availability',
        value: this.availability.value,
        matchMode: 'startsWith'
      });

    if (this.status !== null) //
      filters.push({
        field: 'status',
        value: this.status.value,
        matchMode: 'startsWith'
      });

    if (filters.length > 0) {
      filter = encodeURIComponent(JSON.stringify(filters));
      this.isFilterTouched = true;
    }

    // this.total = -1;
    this.pages = -1;
    this.records = [];

    let first = 0;
    let rows = 5;
    let sorts;

    if ((event !== undefined) && (event !== null)) {
      this.lastLazyLoadEvent = event;

      let properties = TableService.getProperties(event);
      // console.log(properties);

      first = properties.first!;
      rows = properties.rows!;
      sorts = encodeURIComponent(JSON.stringify(properties.sorts!));
    }

    this.productService.all(this.start, this.end, filter, first, rows, sorts).then(data => {
      this.total = data.total;
      this.pages = data.pages;

      setTimeout((): void => {
        this.records = data.items;
        this.isLoading = false;
      }, 100);
    }).catch(() => {
      this.isLoading = false;
    });
  }

  public filter(): void {
    this.isFilterTouched = true;
    this.load();
  }

  public clearFilter(): void {
    this.code = null;
    this.name = null;
    this.communication = null;
    this.kind = null;
    this.availability = null;
    this.status = null;

    // this.start = new Date();
    // this.start.setMonth(this.start.getMonth() - 3);
    this.start = new Date(2017, 0, 1);
    this.end = new Date();

    this.isLoading = false;
    this.isFilterTouched = false;
  }

  public add(): void {
    this.router.navigate(['register', 'product', 'new'], {
      queryParams: {
        token: SessionService.getToken()
      }
    });
  }

  public edit(event: any, item: any): void {
    this.item = item;

    this.router.navigate(['register', 'product', this.item.id], {
      queryParams: {
        token: SessionService.getToken()
      }
    });
  }

  public onOpenMenu(event: any, item: any): void {
    this.item = item;
  }

  public duplicate(event: Event, item: any): void {
    this.confirmationService.confirm({
      target: event.target!,
      header: 'Confirmação',
      message: 'Deseja realmente duplicar <strong>' + item.name + '</strong>?',
      icon: 'fa-solid fa-copy',
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      defaultFocus: 'reject',
      accept: (): void => {
        // TODO: Remove. Its deprecated.
        this.router.routeReuseStrategy.shouldReuseRoute = (): boolean => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['register', 'product', 'new'], {
          queryParams: {
            token: SessionService.getToken(),
            duplicate: item.id,
            time: new Date().getTime(),
          }
        });
      },
      reject: (): void => {
        // reject action
      }
    });
  }

  public delete(event: Event, item: any): void {
    this.confirmationService.confirm({
      target: event.target!,
      header: 'Confirmação',
      message: 'Deseja realmente remover <strong>' + item.name + '</strong>?',
      icon: 'fa-solid fa-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      defaultFocus: 'reject',
      accept: (): void => {
        this.productService.delete(item.id).subscribe({
          next: (): void => { // data
            this.load(this.lastLazyLoadEvent);
          },
          error: (): void => { // err
          },
          complete: (): void => {
          }
        });
      },
      reject: (): void => {
        // reject action
      }
    });
  }

}
