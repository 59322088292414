import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {SessionService} from '../../../services/session/session.service';
import {ScrollService} from '../../../services/scroll/scroll.service';
import {FileUpload} from 'primeng/fileupload';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {FileService} from '../../../services/file/file.service';
import {ValidatorService} from '../../../services/validator/validator.service';
import {Communication} from '../../../models/communication';
import {CommunicationService} from '../../../services/communication/communication.service';

@Component({
  selector: 'app-register-communication-new',
  templateUrl: './register-communication-new.component.html',
  styleUrls: ['./register-communication-new.component.scss']
})
export class RegisterCommunicationNewComponent implements OnInit {

  id: any;
  isLoading: boolean = false;

  name: string = '';
  fileUploadChooseLabel: string = 'Selecionar imagem';
  @ViewChild('imageInput')
    // imageInput: ElementRef<FileUpload> | null = null;
  imageInput: FileUpload | null = null;
  imageInputEl: ElementRef | null = null;

  canCropImage: boolean = false;
  fileName: string = '';
  imageBase64: string = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    private communicationService: CommunicationService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private renderer: Renderer2
  ) {
  }

  public ngOnInit(): void {
    if (environment.useToken) {
      this.route.queryParams.subscribe(params => {
        if (params['token']) {
          SessionService.setToken(params['token']);
          this.init();
        } else {
          this.router.navigateByUrl('/login').then(() => {
          });
        }
      });
    } else {
      this.init();
    }
  }

  private init(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.id = paramMap.get('id');
    });

    ScrollService.toTop();
  }

  public cancel(): void {
    this.router.navigate(['register', 'communication'], {
      queryParams: {
        token: SessionService.getToken()
      }
    });
  }

  public confirm(): void {
    if (ValidatorService.isEmpty(this.name)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O nome precisa ser fornecido.'
      });
      return;
    }

    if (ValidatorService.isEmpty(this.fileName)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'A imagem precisa ser fornecida.'
      });
      return;
    }

    const communication = {
      name: this.name,
      image: this.croppedImage
    } as Communication;
    this.communicationService.add(communication).then(data => {
      //console.log(data);

      if (data && data.added) {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'As informações foram salvas.'
        });
        this.cancel();
      }
    });
  }

  public onFileUpload(event: any): void {
    // console.log(event);

    this.imageInput?.clear();

    // // @ts-ignore
    // // ElementRef
    // this.imageInputEl = this.imageInput?.el.nativeElement;
    //
    // if (this.imageInputEl !== null) //
    //   this.renderer.setStyle(this.imageInputEl, 'display', 'none');

    // if (event.files.length > 0) {
    //   const file = event.files[0];
    //   // const type = file.type;
    //
    //   FileService.load(file).then((base64: string): any => {
    //     // console.log(base64);
    //     this.imageBase64 = base64;
    //     // this.fileBlob = this.b64Blob([base64], type);
    //     // console.log(this.fileBlob)
    //   });
    // }

    if (event.files.length > 0) //
      FileService.load(event.files[0]).then((base64: string): any => {
        this.fileUploadChooseLabel = 'Mudar imagem';
        this.fileName = event.files[0].name;
        this.canCropImage = true;
        this.imageBase64 = base64;
      });
  }

  // public fileChangeEvent(event: any): void {
  //   this.imageChangedEvent = event;
  // }

  public imageCropped(event: ImageCroppedEvent): void {
    // console.log(event);
    this.croppedImage = event.base64;
  }

  public imageLoaded(): void {
    // show cropper
  }

  public cropperImageReady(): void {
    // cropper ready
  }

  public loadImageFailed(): void {
    // show message
  }

}
