import {Injectable} from '@angular/core';
import {ComboBoxOption} from '../../../../models/combo-box-option';

@Injectable({
  providedIn: 'root'
})
export class ReportFilterFieldService {

  constructor() {
  }

  static readonly INVENTORY_OPTIONS: ComboBoxOption[] = [
    {label: 'Com', value: 'notEmpty'},
    {label: 'Sem', value: 'empty'}
  ];

  static hasCode(filters: any, afilters: any[]): void {
    if (filters.code !== undefined) //
      afilters.push({
        field: 'codigo',
        value: '' + filters.code,
        matchMode: 'startsWith'
      });
  }

  static hasKind(filters: any, afilters: any[]): void {
    if (filters.kind !== undefined) //
      afilters.push({
        field: 'tipo',
        value: '' + filters.kind,
        matchMode: 'startsWith'
      });
  }

  static hasAvailability(filters: any, afilters: any[]): void {
    if (filters.availability !== undefined) //
      afilters.push({
        field: 'disponibilidade',
        value: '' + filters.availability,
        matchMode: 'startsWith'
      });
  }

  static hasValidity(filters: any, afilters: any[]): void {
    if (filters.validity !== undefined) //
      afilters.push({
        field: 'validade',
        value: '' + filters.validity,
        matchMode: 'startsWith'
      });
  }

  static hasInventory(filters: any, afilters: any[]): void {
    if (filters.inventory !== undefined) //
      switch (filters.inventory) {
        case 'empty':
          afilters.push({
            field: 'estoque',
            value: '0',
            matchMode: 'equals'
          });
          break;

        case 'notEmpty':
          afilters.push({
            field: 'estoque',
            value: '0',
            matchMode: 'notEquals'
          });
          break;
      }
  }

  static hasAdvertising(filters: any, afilters: any[]): void {
    if (filters.advertising !== undefined) //
      afilters.push({
        field: 'acaoComunicacao',
        value: '' + filters.advertising,
        matchMode: 'startsWith'
      });
  }

  static hasDetail(filters: any, afilters: any[], field: string): void {
    if (filters.detail !== undefined) //
      afilters.push({
        field: field,
        value: filters.detail,
        matchMode: 'contains'
      });
  }

  static hasAdvertisingAgency(filters: any, afilters: any[]): void {
    if (filters.advertisingAgency !== undefined) //
      afilters.push({
        field: 'agenciaPublicidade',
        value: filters.advertisingAgency,
        matchMode: 'startsWith'
      });
  }

  static hasProvider(filters: any, afilters: any[]): void {
    if (filters.provider !== undefined) //
      afilters.push({
        field: 'fornecedor',
        value: filters.provider,
        matchMode: 'startsWith'
      });
  }

}
