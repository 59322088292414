import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-characters-remaining',
  templateUrl: './characters-remaining.component.html',
  styleUrls: ['./characters-remaining.component.scss']
})
export class CharactersRemainingComponent implements OnInit {

  public limit: number = 0;

  @Input()
  public value: string | null = null;

  @Input()
  public field: any | null = null;

  @Input()
  public maxlength: number | null = null;

  @Input()
  public block: boolean = true;

  constructor() {
  }

  public ngOnInit(): void {
    this.limit = 0;
    let length = this.field.getAttribute('maxlength');

    if (length === null && this.maxlength !== null) //
      length = this.maxlength;

    try {
      if (length !== null) //
        this.limit = parseInt(length, 10);
    } catch (e) {
      this.limit = 0;
    }
  }

}
