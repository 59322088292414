import {Injectable} from '@angular/core';
import {
  ReportFilterReport001Service
} from './report-filter-report/report-filter-report001/report-filter-report001.service';
import {
  ReportFilterReport002Service
} from './report-filter-report/report-filter-report002/report-filter-report002.service';
import {
  ReportFilterReport003Service
} from './report-filter-report/report-filter-report003/report-filter-report003.service';
import {
  ReportFilterReport004Service
} from './report-filter-report/report-filter-report004/report-filter-report004.service';
import {
  ReportFilterReport005Service
} from './report-filter-report/report-filter-report005/report-filter-report005.service';
import {
  ReportFilterReport006Service
} from './report-filter-report/report-filter-report006/report-filter-report006.service';
import {
  ReportFilterReport007Service
} from './report-filter-report/report-filter-report007/report-filter-report007.service';
import {
  ReportFilterReport008Service
} from './report-filter-report/report-filter-report008/report-filter-report008.service';
import {
  ReportFilterReport009Service
} from './report-filter-report/report-filter-report009/report-filter-report009.service';
import {
  ReportFilterReport010Service
} from './report-filter-report/report-filter-report010/report-filter-report010.service';
import {
  ReportFilterReport011Service
} from './report-filter-report/report-filter-report011/report-filter-report011.service';
import {
  ReportFilterReport012Service
} from './report-filter-report/report-filter-report012/report-filter-report012.service';
import {
  ReportFilterReport013Service
} from './report-filter-report/report-filter-report013/report-filter-report013.service';
import {
  ReportFilterReport014Service
} from './report-filter-report/report-filter-report014/report-filter-report014.service';

@Injectable({
  providedIn: 'root'
})
export class ReportFilterService {

  constructor() {
  }

  static generate(reportKind: number | string | boolean, filters: any): string {
    const afilters: any[] = [];

    switch (reportKind) {
      case '001': // Peças > Geral
        ReportFilterReport001Service.generate(filters, afilters);
        break;

      case '002': // Peças > Expurgo
        ReportFilterReport002Service.generate(filters, afilters);
        break;

      case '003': // Peças > Avise-me
        ReportFilterReport003Service.generate(filters, afilters);
        break;

      case '004': // Peças > Preços
        ReportFilterReport004Service.generate(filters, afilters);
        break;

      case '005': // Pedidos > Geral
        ReportFilterReport005Service.generate(filters, afilters);
        break;

      case '006': // Pedidos > Cancelados
        ReportFilterReport006Service.generate(filters, afilters);
        break;

      case '007': // Pedidos > Pesquisa de avaliação
        ReportFilterReport007Service.generate(filters, afilters);
        break;

      case '008': // Pedidos > Pesquisa de avaliação (reprovadas)
        ReportFilterReport008Service.generate(filters, afilters);
        break;

      case '009': // Pedidos > Caixa
        ReportFilterReport009Service.generate(filters, afilters);
        break;

      case '010': // Usuários > Geral
        ReportFilterReport010Service.generate(filters, afilters);
        break;

      case '011': // Fale conosco > Geral
        ReportFilterReport011Service.generate(filters, afilters);
        break;

      case '012': // Peças > Estoque
        ReportFilterReport012Service.generate(filters, afilters);
        break;

      case '013': // Pedido > Avaliação
        ReportFilterReport013Service.generate(filters, afilters);
        break;

      case '014': // Peças > Avaliação
        ReportFilterReport014Service.generate(filters, afilters);
        break;
    }

    return (afilters.length > 0) ? JSON.stringify(afilters) : '';
  }

}
