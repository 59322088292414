import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {SessionService} from '../session/session.service';
import {lastValueFrom} from 'rxjs';
import {PublicityAgency} from '../../models/publicity-agency';

@Injectable({
  providedIn: 'root'
})
export class PublicityAgencyService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl + '/register/publicity-agency';
  }

  public all(term: string | null = null): Promise<PublicityAgency[]> {
    const url: string = this.baseUrl + '/?token=' + SessionService.getToken() + '&combobox=true&term=' + term;
    const encoded: string = encodeURI(url);

    return lastValueFrom(this.http.get<any>(encoded))
      .then(res => <PublicityAgency[]>res)
      .then((data: PublicityAgency[]): PublicityAgency[] => data);
  }

  public add(publicityAgency: PublicityAgency): Promise<any> {
    const url: string = this.baseUrl + '/?token=' + SessionService.getToken();

    return lastValueFrom(this.http.post<any>(url, publicityAgency))
      // .then(res => res.added)
      .then(data => data);
  }

}
